import { createReducer } from '@reduxjs/toolkit';
import { ProductCatalogsFilterField } from 'entries/catalogs/filters/productCatalogsFilter';
import {
  fetchIntegratedProductCatalogs,
  setIntegratedProductCatalogs,
  setIntegratedProductCatalogsSearch,
} from './actions';
import { IntegratedProductCatalogsState } from './Models';

export const initialState: IntegratedProductCatalogsState = {
  count: 0,
  items: [],
  pageIndex: 0,
  pageSize: 0,
  isLoading: true,
  error: null,
  productCatalogsFilter: {
    [ProductCatalogsFilterField.Search]: '',
  },
};

export const integratedProductCatalogs = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(setIntegratedProductCatalogs, (state, action) => {
        state.items = action.payload;
      })
      .addCase(fetchIntegratedProductCatalogs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchIntegratedProductCatalogs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.count = action.payload.count;
        state.pageSize = action.payload.pageSize;
        state.pageIndex = action.payload.pageIndex;
        state.items = action.payload.items.map((catalog) => ({
          ...catalog,
          tableData: {
            checked: false,
          },
        }));
      })
      .addCase(fetchIntegratedProductCatalogs.rejected, (state, action) => {
        state.error = action.payload || 500;
        state.isLoading = false;
      })
      .addCase(setIntegratedProductCatalogsSearch, (state, action) => {
        state.productCatalogsFilter = action.payload;
      });
  },
);
