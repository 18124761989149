import { createReducer } from '@reduxjs/toolkit';
import { restoreCatalogItems, restoreCatalogItemsProduct } from './actions';
import { CatalogItemState } from './Models';

export const initialState: CatalogItemState = {
  catalogItems: [],
  error: null,
  isLoading: true,
};

export const catalogItemState = createReducer(initialState, (builder) => {
  builder
    .addCase(restoreCatalogItems.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(restoreCatalogItems.fulfilled, (state, action) => {
      state.catalogItems = action.payload;
      state.isLoading = false;
    })
    .addCase(restoreCatalogItems.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    })
    .addCase(restoreCatalogItemsProduct.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(restoreCatalogItemsProduct.fulfilled, (state, action) => {
      state.catalogItems = action.payload;
      state.isLoading = false;
    })
    .addCase(restoreCatalogItemsProduct.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    });
});
