export const RESPONSE_STATUS_400 = 400;
export const RESPONSE_STATUS_401 = 401;
export const RESPONSE_STATUS_403 = 403;
export const RESPONSE_STATUS_404 = 404;
export const RESPONSE_STATUS_406 = 406;
export const RESPONSE_STATUS_419 = 419;
export const RESPONSE_STATUS_500 = 500;
export const RESPONSE_STATUS_503 = 503;

export const RESPONSE_NOT_FOUND = 'Not Found';
export const CATALOG_ASSIGNED_TO_CLIENT = 'CatalogAssignedToClient';
export const localStoragePreviousPagePath = 'previousPagePath';
export const timeoutMessage =
         'Once a report is generated, this button will remain disabled for 1 minute.';

export const imgSizes = {
  small: '64',
  medium: '128',
  large: '512',
};

export const DISABLE_REPORT_AND_SEARCH = false;
export const MAXIMUM_CHECKOUT_QUANTITY = 1000;

export const CUSTOMIZED_PERCENTAGE_DEFAULT = 'CustomizedPercentage_Default';

export const DISABLE_TIMEOUT = 60000;

export const QA_TEST_CLIENT_EMAIL = 'QA_Testing@bamboo-card.com';
