import { createReducer } from '@reduxjs/toolkit';
import { AvailableCatalogBrands } from './Models';
import {
  fetchAvailableBrands,
  saveFilterStatusAvailableCatalogBrands,
} from 'store/bambooCatalog/availableCatalogBrands/actions';
import { clearCatalogsErrors } from 'store/catalogs/actions';

export const initialState: AvailableCatalogBrands = {
  count: 0,
  items: [],
  pageIndex: 0,
  pageSize: 0,
  isLoading: false,
  isFilterActive: false,
  error: null,
};

export const availableCatalogBrands = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAvailableBrands.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchAvailableBrands.fulfilled, (state, action) => {
      return { ...state, ...action.payload, isLoading: false };
    })
    .addCase(fetchAvailableBrands.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    })
    .addCase(clearCatalogsErrors, (state) => {
      state.error = null;
    })
    .addCase(saveFilterStatusAvailableCatalogBrands, (state, action) => {
      state.isFilterActive = action.payload;
    });
});
