export enum IntegrationType {
  API = 'Api',
  MANUAL = 'Manual',
  Eneba = 'Eneba',
  EnebaDeclaredStock = 'EnebaDeclaredStock',
  Gamivo = 'Gamivo',
  G2A = 'G2A',
  Binance = 'Binance',
  Buffering = 'Buffering',
  Kinguin = 'Kinguin',
}
