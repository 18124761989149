import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, AvailableCatalogModel } from './Models';

export const getAvailableCatalogs = async (
  params: Arguments,
): Promise<AvailableCatalogModel> => {
  const response = await apiClient.get(ENDPOINTS.CATALOGS, { params });
  return response.data;
};
