import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { BinanceRequestModel, Arguments } from './Models';

export const getBinanceRequest = async (
  params: Arguments,
): Promise<BinanceRequestModel> => {
  const response = await apiClient.get(`${ENDPOINTS.BINANCE}/orders`, {
    params,
  });

  return response.data;
};
