import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  Response,
  Arguments,
} from 'api/catalogs/fetchAvailableCatalogClients/Models';
import { fetchAvailableCatalogClients } from 'api/catalogs/fetchAvailableCatalogClients';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchAvailableClients = createAsyncThunk<
  Response,
  { catalogId: string } & { args: Arguments },
  AsyncThunkOptions
>(
  'availableCatalogClients/fetchAvailableClients',
  async (params, { rejectWithValue }) => {
    try {
      return await fetchAvailableCatalogClients(params.catalogId, params.args);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const saveFilterStatusAvailableCatalogClients = createAction<boolean>(
  'availableCatalogClients/saveFilterStatusCatalogClients',
);
