import { createReducer } from '@reduxjs/toolkit';
import {
  fetchClientCatalog,
  resetClientCatalog,
  saveFilterStatusClientCatalog,
} from 'store/catalog/clientCatalog/actions';
import { clearCatalogsErrors } from 'store/catalogs/actions';
import { State } from './Models';

export const initialState: State = {
  count: 0,
  items: [],
  pageIndex: 0,
  pageSize: 0,
  error: null,
  isLoading: true,
  isFilterActive: false,
};

export const clientCatalog = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchClientCatalog.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchClientCatalog.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    })
    .addCase(fetchClientCatalog.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    })
    .addCase(saveFilterStatusClientCatalog, (state, action) => {
      state.isFilterActive = action.payload;
    })
    .addCase(clearCatalogsErrors, (state) => {
      state.error = null;
    })
    .addCase(resetClientCatalog, () => initialState);
});
