import { createReducer } from '@reduxjs/toolkit';
import { AvailableCatalogProducts } from './Models';
import {
  fetchAvailableProducts,
  saveFilterStatusAvailableCatalogProducts,
} from 'store/catalog/availableCatalogProducts/actions';
import { clearCatalogsErrors } from 'store/catalogs/actions';

export const initialState: AvailableCatalogProducts = {
  count: 0,
  items: [],
  pageIndex: 0,
  pageSize: 0,
  isLoading: false,
  isFilterActive: false,
  error: null,
};

export const availableCatalogProducts = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(fetchAvailableProducts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAvailableProducts.fulfilled, (state, action) => {
        return { ...state, ...action.payload, isLoading: false };
      })
      .addCase(fetchAvailableProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 500;
      })
      .addCase(clearCatalogsErrors, (state) => {
        state.error = null;
      })
      .addCase(saveFilterStatusAvailableCatalogProducts, (state, action) => {
        state.isFilterActive = action.payload;
      });
  },
);
