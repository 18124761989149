import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, AnalyticsModel } from './Models';

export const getAnalyticsReport = async (
  params: Arguments,
): Promise<AnalyticsModel> => {
  const response = await apiClient.get(ENDPOINTS.ANALYTICS_REPORT, { params });

  return response.data;
};
