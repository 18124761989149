import { createReducer } from '@reduxjs/toolkit';
import {
  fetchProducts,
  setInitialCatalog,
  saveFilterStatusCatalogProducts,
} from 'store/catalog/catalogProducts/actions';
import { clearCatalogsErrors } from 'store/catalogs/actions';
import { State } from './Models';

export const initialState: State = {
  count: 0,
  items: [],
  pageIndex: 0,
  pageSize: 0,
  error: null,
  isLoading: true,
  isFilterActive: false,
};

export const catalogProducts = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchProducts.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchProducts.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        items: action.payload.items.map((product) => ({
          ...product,
          isValid: true,
          tableData: {
            checked: false,
          },
        })),
      };
    })
    .addCase(fetchProducts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    })
    .addCase(saveFilterStatusCatalogProducts, (state, action) => {
      state.isFilterActive = action.payload;
    })
    .addCase(clearCatalogsErrors, (state) => {
      state.error = null;
    })
    .addCase(setInitialCatalog, (state) => {
      state.count = initialState.count;
      state.items = initialState.items;
      state.pageSize = initialState.pageSize;
      state.pageIndex = initialState.pageIndex;
      state.error = initialState.error;
      state.isLoading = initialState.isLoading;
      state.isFilterActive = initialState.isFilterActive;
    });
});
