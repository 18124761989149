import { createReducer } from '@reduxjs/toolkit';
import { CategoryFilterField } from 'entries/categories/filter';
import { fetchCategories, setCategoriesSearch } from './actions';
import { CategoryState } from './Models';

export const initialState: CategoryState = {
  filter: {
    [CategoryFilterField.Search]: '',
    [CategoryFilterField.Status]: '',
  },
  categories: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: true,
  error: null,
};

export const categories = createReducer(initialState, (builder) => {
  builder
    .addCase(setCategoriesSearch, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(fetchCategories.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.categories = action.payload;
    })
    .addCase(fetchCategories.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    });
});
