import { createReducer } from '@reduxjs/toolkit';

import { G2aSelectTableState } from './Models';
import { fetchG2aProductSelectTable } from './actions';

export const initialState: G2aSelectTableState = {
  g2aSelectProduct: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: true,
  isFilterActive: false,
  error: null,
};

export const g2aSelectTableProduct = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchG2aProductSelectTable.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchG2aProductSelectTable.fulfilled, (state, action) => {
      state.isLoading = false;
      state.g2aSelectProduct = action.payload;
    })
    .addCase(fetchG2aProductSelectTable.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    });
});
