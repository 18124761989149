import {
  Arguments,
  Response,
} from 'api/inventoryStatistics/getInventoryStatistics/Models';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getInventoryStatistics } from 'api/inventoryStatistics/getInventoryStatistics';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchInventoryStatistics = createAsyncThunk<
  Response,
  Arguments,
  AsyncThunkOptions
>(
  'suppliers/fetchInventoryInvoices',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getInventoryStatistics(queryParams);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
