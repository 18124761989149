import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAvailableCatalogsBrands } from 'api/bambooCatalogs/fetchAvailableCatalogsBrands';
import {
  Arguments,
  AvailableBrandsResponse,
} from 'api/bambooCatalogs/fetchAvailableCatalogsBrands/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchAvailableBrands = createAsyncThunk<
  AvailableBrandsResponse,
  { catalogId: string } & { args: Arguments },
  AsyncThunkOptions
>(
  'availableCatalogBrands/fetchAvailableBrands',
  async (params, { rejectWithValue }) => {
    try {
      return await fetchAvailableCatalogsBrands(params.catalogId, {
        ...params.args,
        isIntegrated: false,
      });
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const saveFilterStatusAvailableCatalogBrands = createAction<boolean>(
  'availableCatalogBrands/saveFilterStatusCatalogBrands',
);
