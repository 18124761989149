import { FilterInputs } from 'entries/inventoryReports';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getInventoryReports } from 'api/inventoryReports/fetchInventoryReports';
import {
  Arguments,
  Response as ReportState,
} from 'api/inventoryReports/Models';

export const setInventoryReportsSearch = createAction<FilterInputs>(
  'reports/setInventoryReportsSearch',
);

export const clearInventoryReportsFilters = createAction(
  'reports/clearInventoryReportsFilters',
);

export const clearInventoryReportsErrors = createAction(
  'reports/clearInventoryReportsErrors',
);

export const fetchInventoryReports = createAsyncThunk<
  ReportState,
  Arguments,
  { rejectValue: number }
>('reports/fetchInventoryReports', async (queryParams, { rejectWithValue }) => {
  try {
    return await getInventoryReports(queryParams);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});
