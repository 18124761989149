import { getProductG2aMainTable } from '../../../api/g2a/getProductG2aMainTable/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  G2aProductsMainTableModel,
  Arguments,
} from '../../../api/g2a/getProductG2aMainTable/Models';
import { toastMessage } from 'utils/toastMessage';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchG2aProductMainTable = createAsyncThunk<
  G2aProductsMainTableModel,
  Arguments,
  AsyncThunkOptions
>('g2a/fetchMainTableProductG2a', async (queryParams, { rejectWithValue }) => {
  try {
    return await getProductG2aMainTable(queryParams);
  } catch (error) {
    toastMessage(error.response.data.message, 'error', 'top-right');
    console.error(error.response.data);
    return rejectWithValue(error.response.status);
  }
});
