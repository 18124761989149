import { Metrics } from 'api/clients/Models';

export enum ClientSetting {
  Id = 'id',
  Priority = 'priority',
  Seconds = 'seconds',
  IpList = 'ipList',
  Metrics = 'metrics',
}

export interface ClientSettingInputs {
  [ClientSetting.Id]: number | undefined;
  [ClientSetting.Priority]: string;
  [ClientSetting.Metrics]: Metrics[];
  [ClientSetting.IpList]: string[];
}
