import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { MarketplaceOrder } from './Models';
import { IntegrationType } from 'entries/clientsList/integrationType';

export const getMarketplaceOrderById = async (
  orderId: string,
  integrationType: IntegrationType,
): Promise<MarketplaceOrder> => {
  const requestEndpoint =
    integrationType === IntegrationType.EnebaDeclaredStock
      ? ENDPOINTS.ENEBA
      : integrationType === IntegrationType.Gamivo
      ? ENDPOINTS.GAMIVO
      : integrationType === IntegrationType.G2A
      ? ENDPOINTS.G2A
      : integrationType === IntegrationType.Kinguin
      ? ENDPOINTS.KINGUIN
      : ENDPOINTS.BINANCE;
  const response = await apiClient.get(
    `${requestEndpoint}/get-order-number/${orderId}`,
  );

  return response.data;
};
