import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, KinguinProductsMainTableModel } from './Models';

export const getProductKinguinMainTable = async (
  params: Arguments,
): Promise<KinguinProductsMainTableModel> => {
  const response = await apiClient.get(
    `${ENDPOINTS.KINGUIN}${ENDPOINTS.PRODUCTS}`,
    {
      params,
    },
  );

  return response.data;
};
