import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { FilterInputs } from 'entries/users';
import { UsersListResponse } from './Models';

export interface Arguments extends FilterInputs {
  pageSize?: number | string;
  pageIndex?: number | string;
}

export const fetchUsersList = async (
  params: Arguments,
): Promise<UsersListResponse> => {
  const response = await apiClient.get(`${ENDPOINTS.USERS}`, { params });

  return response.data;
};
