import { AnalyticsModel, Arguments } from 'api/analytics/getReport/Models';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAnalyticsReport } from '../../api/analytics/getReport';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchAnalyticsReport = createAsyncThunk<
  AnalyticsModel,
  Arguments,
  AsyncThunkOptions
>(
  'analytics/fetchAnalyticsReport',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getAnalyticsReport(queryParams);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
