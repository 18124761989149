export enum CatalogsListFilterField {
  Search = 'searchText',
  SuppliersId = 'supplierId',
  Country = 'country',
  Client = 'clientId',
  Brand = 'brandId',
}

export interface CatalogsListFilterInputs {
  [CatalogsListFilterField.Brand]: string;
  [CatalogsListFilterField.Search]: string;
  [CatalogsListFilterField.Country]: string;
  [CatalogsListFilterField.Client]: string;
  [CatalogsListFilterField.SuppliersId]: string;
}
