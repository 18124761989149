export enum BrandCatalogFilterField {
  CatalogName = 'searchText',
  Status = 'isActive',
  Discount = 'discountValue',
  Fee = 'transactionFeeValue',
  SupplierFee = 'supplierFee',
}

export interface BrandCatalogFilterInputs {
  [BrandCatalogFilterField.CatalogName]: string;
  [BrandCatalogFilterField.Status]: string;
  [BrandCatalogFilterField.Discount]: string;
  [BrandCatalogFilterField.Fee]: string;
  [BrandCatalogFilterField.SupplierFee]: string;
}
