import { createReducer } from '@reduxjs/toolkit';

import { ReconciliationState } from './Models';
import { fetchDiscrepancy, fetchReconciliationAccounts } from './action';

export const initialState: ReconciliationState = {
  reconciliationAccounts: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  discrepancy: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: true,
  isFilterActive: false,
  error: null,
};

export const reconciliationAccountsState = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(fetchReconciliationAccounts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchReconciliationAccounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reconciliationAccounts = action.payload;
      })
      .addCase(fetchReconciliationAccounts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 500;
      })
      .addCase(fetchDiscrepancy.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDiscrepancy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.discrepancy = action.payload;
      })
      .addCase(fetchDiscrepancy.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 500;
      });
  },
);
