import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, Response } from './brandCatalog';

export const getBrandCatalogsApi = async (
  brandId: number,
  params: Arguments,
): Promise<Response> => {
  const response = await apiClient.get(
    `${ENDPOINTS.PRODUCTS}/brand-catalogs/${brandId}`,
    {
      params,
    },
  );
  return response.data;
};
