import React, { useEffect } from 'react';

import { routes } from '../../routes/routes';
import { useSelector } from 'react-redux';
import { shoppingCartsList } from '../../store/shoppingCart/selectors';
import { getShoppingCartsList } from '../../api/shoppingCart';
import MaintenanceForm from './MaintenanceForm';

const Maintenance = () => {
  const cartList = useSelector(shoppingCartsList);

  const data = async () => {
    try {
      return await getShoppingCartsList({ pageSize: 100, pageIndex: 0 });
    } catch (error) {
      if (error.response && error.response.status >= 500) {
        console.error({
          message: error.response.data.message,
          status: error.response.status,
          statusText: error.response.statusText,
        });
      }
    }
  };

  useEffect(() => {
    let interval: number;
    let timer: number;

    // eslint-disable-next-line prefer-const
    interval = setInterval(() => {
      void data().then((r) => {
        if (r) {
          window.location.assign(routes.index);
        }
      });
    }, 7000);

    // eslint-disable-next-line prefer-const
    timer = setTimeout(() => {
      clearInterval(interval);
    }, 300000);

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, [cartList]);

  return <MaintenanceForm />;
};

export default Maintenance;
