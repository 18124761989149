import { ENDPOINTS } from 'api/endpoints';
import { apiClient } from 'config/auth';
import { Options } from 'entries/filters';
import { XeroContact } from 'entries/xero';

type Response = {
  data: XeroContact;
};

export const getXeroContacts = async (): Promise<Options> => {
  const response: Response = await apiClient.get(`${ENDPOINTS.XERO}/contacts`);

  const result = response.data.map((item) => ({
    value: item.id,
    name: item.name,
  }));
  return result;
};
