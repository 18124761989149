import { createReducer } from '@reduxjs/toolkit';
import { UsersState } from 'store/users/Models';
import {
  deleteUser,
  getUsersList,
  getActiveUser,
  addNewUserToList,
  updateUserInList,
  changeUserStatus,
  updateCurrentUser,
  removeCurrentUser,
} from 'store/users/actions';
import { userRole } from '../../config/RBAC/RBAC';

export const initialState: UsersState = {
  usersList: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  activeUser: {
    isLoading: true,
    user: {
      imageUrl: '',
      imageName: '',
      id: 0,
      firstName: '',
      lastName: '',
      userName: '',
      clientName: '',
      clientId: 0,
      email: '',
      mobileNumber: '',
      isAdmin: false,
      status: 'Enabled',
      systemRoleId: 0,
      isMfaEnabled: false,
      isMfaSetup: false,
    },
  },
  isLoading: true,
  errors: {
    usersList: null,
    activeUser: null,
  },
};

export const users = createReducer(initialState, (builder) => {
  builder
    .addCase(getUsersList.pending, (state) => {
      state.isLoading = true;
      state.errors.usersList = null;
    })
    .addCase(getUsersList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.usersList = action.payload;
    })
    .addCase(getUsersList.rejected, (state, action) => {
      state.isLoading = false;
      state.errors.usersList = action.payload || 500;
    })
    .addCase(getActiveUser.pending, (state) => {
      state.activeUser.isLoading = true;
      state.errors.activeUser = null;
    })
    .addCase(getActiveUser.fulfilled, (state, action) => {
      state.activeUser.isLoading = false;
      let newData = action.payload;

      if (action.payload.systemRoleId === userRole.SuperAdmin) {
        newData = { ...action.payload, isAdmin: true };
      } else if (action.payload.systemRoleId === userRole.Admin) {
        newData = { ...action.payload, isAdmin: true };
      } else if (action.payload.systemRoleId === userRole.FinanceManager) {
        newData = { ...action.payload, isFinanceManager: true };
      } else if (action.payload.systemRoleId === userRole.CustomerSupport) {
        newData = { ...action.payload, isCustomerSupport: true };
      } else if (action.payload.systemRoleId === userRole.Sales) {
        newData = { ...action.payload, isSales: true };
      } else if (action.payload.systemRoleId === userRole.OperationsExecutive) {
        newData = { ...action.payload, isOperationsExecutive: true };
      } else if (action.payload.systemRoleId === userRole.AccountManager) {
        newData = { ...action.payload, isAccountManager: true };
      } else if (action.payload.systemRoleId === userRole.MarketplaceManager) {
        newData = { ...action.payload, isMarketplaceManager: true };
      } else if (action.payload.systemRoleId === userRole.Tech) {
        // Setting isAdmin to true to enable all admin functionalities and restrict some functionalities
        newData = {
          ...action.payload,
          isTech: true,
          isAdmin: true,
        };
      }

      state.activeUser.user = newData;
    })
    .addCase(getActiveUser.rejected, (state, action) => {
      state.activeUser.isLoading = false;
      state.errors.activeUser = action.payload || 500;
    })
    .addCase(deleteUser, ({ usersList }, action) => {
      if (usersList.items) {
        usersList.items = usersList.items.filter(
          (user) => user.id !== action.payload,
        );
      }
    })
    .addCase(addNewUserToList, ({ usersList }, action) => {
      usersList.items = [action.payload, ...usersList.items];
    })
    .addCase(updateUserInList, ({ usersList }, action) => {
      const userIndex = usersList.items.findIndex(
        (user) => user.id === action.payload.id,
      );

      if (userIndex !== -1) {
        usersList.items[userIndex] = action.payload;
      }
    })
    .addCase(updateCurrentUser, (state, action) => {
      let newData = action.payload;

      if (action.payload.systemRoleId === userRole.SuperAdmin) {
        newData = { ...action.payload, isAdmin: true };
      } else if (action.payload.systemRoleId === userRole.Admin) {
        newData = { ...action.payload, isAdmin: true };
      } else if (action.payload.systemRoleId === userRole.FinanceManager) {
        newData = { ...action.payload, isFinanceManager: true };
      } else if (action.payload.systemRoleId === userRole.CustomerSupport) {
        newData = { ...action.payload, isCustomerSupport: true };
      } else if (action.payload.systemRoleId === userRole.Sales) {
        newData = { ...action.payload, isSales: true };
      } else if (action.payload.systemRoleId === userRole.OperationsExecutive) {
        newData = { ...action.payload, isOperationsExecutive: true };
      } else if (action.payload.systemRoleId === userRole.AccountManager) {
        newData = { ...action.payload, isAccountManager: true };
      } else if (action.payload.systemRoleId === userRole.MarketplaceManager) {
        newData = { ...action.payload, isMarketplaceManager: true };
      } else if (action.payload.systemRoleId === userRole.Tech) {
        // Setting isAdmin to true to enable all admin functionalities and restrict some functionalities
        newData = {
          ...action.payload,
          isTech: true,
          isAdmin: true,
        };
      }
      state.activeUser.user = newData;
    })
    .addCase(removeCurrentUser, (state, action) => {
      state.activeUser.user = initialState.activeUser.user;
      state.activeUser.isLoading = true;
    })
    .addCase(changeUserStatus, ({ usersList }, action) => {
      const userIndex = usersList.items.findIndex(
        (user) => user.id === action.payload.id,
      );

      if (userIndex !== -1) {
        usersList.items[userIndex].status = action.payload.status;
      }
    });
});
