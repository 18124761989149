import { ENDPOINTS } from 'api/endpoints';
import { apiClient } from 'config/auth';
import { XeroAccount } from 'entries/xero';
import { Options } from 'entries/filters';

type Response = {
  data: XeroAccount;
};

export const getXeroAccounts = async (): Promise<Options> => {
  const response: Response = await apiClient.get(`${ENDPOINTS.XERO}/accounts`);

  const result = response.data
    .filter((x) => x.Code)
    .map((item) => ({
      value: item.Code,
      name: `${item.Name} ${item.CurrencyCode ? `(${item.CurrencyCode})` : ''}`,
    }));
  return result;
};
