import {
  Arguments,
  Response,
} from 'api/inventoryInvoices/getInventoryInvoices/Models';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getInventoryInvoices } from 'api/inventoryInvoices/getInventoryInvoices';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchInventoryInvoices = createAsyncThunk<
  Response,
  Arguments,
  AsyncThunkOptions
>(
  'suppliers/fetchInventoryInvoices',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getInventoryInvoices(queryParams);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
