import { ENDPOINTS } from 'api/endpoints';
import { apiClient } from 'config/auth';
import { Arguments, Response } from './Models';
import { omit } from 'lodash';

export const fetchClientCatalog = async (
  params: Arguments,
): Promise<Response> => {
  const response = await apiClient.get(
    params.isV2 ? ENDPOINTS.CLIENT_CATALOGS_V2 : ENDPOINTS.CLIENT_CATALOGS,
    {
      params: omit(params, 'isV2'),
    },
  );

  return response.data;
};
