import { createReducer } from '@reduxjs/toolkit';
import { CardsFilterField } from 'entries/cards';
import {
  fetchCardHistory,
  saveCardsHistoryFilterStatus,
  setCardsHistorySearch,
} from './actions';

import { CardsHistoryState } from './Models';

export const initialState: CardsHistoryState = {
         filter: {
           [CardsFilterField.DateFrom]: '',
           [CardsFilterField.DateFrom]: '',
           [CardsFilterField.DateTo]: '',
           [CardsFilterField.Brand]: '',
           [CardsFilterField.Product]: '',
           [CardsFilterField.FilterBy]: '',
           [CardsFilterField.FilterValue]: '',
           [CardsFilterField.Client]: '',
           [CardsFilterField.Supplier]: '',
           [CardsFilterField.CardStatus]: '',
           [CardsFilterField.CardCode]: '',
           [CardsFilterField.CardStatus]: '',
           [CardsFilterField.CardUrl]: '',
           [CardsFilterField.ExpiringDate]: '',
         },
         cardsHistory: {
           pageSize: 0,
           pageIndex: 0,
           count: 0,
           items: [],
         },
         isLoading: false,
         isFilterActive: false,
         error: null,
       };

export const cardsHistory = createReducer(initialState, (builder) => {
  builder
    .addCase(setCardsHistorySearch, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(fetchCardHistory.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchCardHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.cardsHistory = action.payload;
    })
    .addCase(fetchCardHistory.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    })
    .addCase(saveCardsHistoryFilterStatus, (state, action) => {
      state.isFilterActive = action.payload;
    });
});
