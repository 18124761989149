import {
  Arguments,
  ProductConfigurationModel,
  ProductConfigurations,
  Response,
  Supplier,
  SupplierAccount,
} from 'api/suppliers/Models';
import { getSuppliers } from 'api/suppliers/getSuppliers';
import { getSupplierById } from 'api/suppliers/getSupplierById';
import { getSupplierAccounts } from 'api/suppliers/getSupplierAccounts';
import { FilterInputs } from 'entries/suppliers';
import { getSupplierProductConfigurations } from 'api/suppliers/getSupplierProductConfigurations';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getSupplierLiveCatalogs } from 'api/suppliers/getSupplierLiveCatalogs';
import {
  SupplierLiveCatalogArguments,
  SupplierLiveCatalogResponse,
} from 'api/suppliers/getSupplierLiveCatalogs/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

type Params = {
  pageSize: number;
  pageIndex: number;
  supplierProductCode: string;
};

export const setSuppliersSearch = createAction<FilterInputs>(
  'suppliers/setSuppliersSearch',
);

export const resetActiveSupplierError = createAction(
  'suppliers/resetActiveSupplierError',
);

export const addSupplierToList = createAction<Supplier>(
  'suppliers/addSupplierToList',
);

export const updateSupplier = createAction<Supplier>(
  'suppliers/updateSupplier',
);

export const addProductConfigurationsToList = createAction<
  ProductConfigurations
>('suppliers/addProductConfigurationsToList');

export const updateProductConfigurationsList = createAction<
  ProductConfigurations
>('suppliers/updateProductConfigurationsList');

export const deleteProductConfigurationsFromList = createAction<
  string | number
>('suppliers/deleteProductConfigurationsFromList');

export const fetchSuppliers = createAsyncThunk<
  Response,
  Arguments,
  AsyncThunkOptions
>('suppliers/fetchSuppliers', async (queryParams, { rejectWithValue }) => {
  try {
    return await getSuppliers(queryParams);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});

export const fetchSupplierById = createAsyncThunk<
  Supplier,
  string | number,
  AsyncThunkOptions
>('suppliers/fetchSupplierById', async (supplierId, { rejectWithValue }) => {
  try {
    return await getSupplierById(supplierId);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});

export const fetchSupplierAccounts = createAsyncThunk<
  SupplierAccount[],
  string | number,
  AsyncThunkOptions
>(
  'suppliers/fetchSupplierAccounts',
  async (supplierId, { rejectWithValue }) => {
    try {
      return await getSupplierAccounts(supplierId);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const fetchSupplierProductConfigurations = createAsyncThunk<
  ProductConfigurationModel,
  { supplierId: string | number } & {
    params: Params;
  },
  AsyncThunkOptions
>(
  'suppliers/fetchSupplierProductConfigurations',
  async ({ supplierId, params }, { rejectWithValue }) => {
    try {
      return await getSupplierProductConfigurations(supplierId, params);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const changeStatus = createAction('supplier/changeStatus');

export const fetchSupplierLiveCatalogs = createAsyncThunk<
  SupplierLiveCatalogResponse,
  { supplierCode: string } & { params: SupplierLiveCatalogArguments },
  AsyncThunkOptions
>(
  'products/fetchSupplierCatalogs',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getSupplierLiveCatalogs(
        queryParams.supplierCode,
        queryParams.params,
      );
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const resetSupplierLiveCatalogError = createAction(
  'suppliers/resetSupplierLiveCatalogError',
);
