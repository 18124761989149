import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { SupplierAccount } from 'api/suppliers/Models';

export const getSupplierAccounts = async (
  id: string | number,
): Promise<SupplierAccount[]> => {
  const response = await apiClient.get(`${ENDPOINTS.SUPPLIERS}/${id}/accounts`);

  return response.data;
};
