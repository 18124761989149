import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, Response } from './ProductCatalog';

export const getProductCatalogsApi = async (
  productId: number,
  params: Arguments,
): Promise<Response> => {
  const response = await apiClient.get(
    `${ENDPOINTS.PRODUCTS}/${productId}/product-catalogs`,
    {
      params,
    },
  );
  return response.data;
};
