import { ENDPOINTS } from 'api/endpoints';
import { apiClient } from 'config/auth';
import { Options } from 'entries/filters';
import { XeroTheme } from 'entries/xero';

type Response = {
  data: XeroTheme;
};

export const getXeroThemes = async (): Promise<Options> => {
  const response: Response = await apiClient.get(`${ENDPOINTS.XERO}/themes`);

  const result = response.data.map((item) => ({
    value: item.BrandingThemeID,
    name: item.Name,
  }));
  return result;
};
