import { createReducer } from '@reduxjs/toolkit';
import { BinanceRequestListFilterField } from 'entries/binance';
import { fetchBinanceRequests } from './actions';

import { BinanceRequestState } from './Models';

export const initialState: BinanceRequestState = {
  filter: {
    [BinanceRequestListFilterField.DateFrom]: '',
    [BinanceRequestListFilterField.DateTo]: '',
    [BinanceRequestListFilterField.Status]: '',
    [BinanceRequestListFilterField.ClientEmail]: '',
    [BinanceRequestListFilterField.PrePayId]: '',
    [BinanceRequestListFilterField.CustomerReferenceIdentifier]: '',
    [BinanceRequestListFilterField.HasOrder]: '',
  },
  binanceRequests: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: true,
  isFilterActive: false,
  error: null,
};

export const binanceRequest = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchBinanceRequests.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchBinanceRequests.fulfilled, (state, action) => {
      state.isLoading = false;
      state.binanceRequests = action.payload;
    })
    .addCase(fetchBinanceRequests.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    });
});
