import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCatalogBrandsWithFilter } from 'api/bambooCatalogs/fetchCatalogBrandsWithFilter';
import {
  CatalogBrandsResponse,
  Arguments,
} from 'api/bambooCatalogs/fetchCatalogBrandsWithFilter/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchEditableBrands = createAsyncThunk<
  CatalogBrandsResponse,
  { catalogId: string } & { args: Arguments },
  AsyncThunkOptions
>(
  'editableCatalogBrands/fetchEditableBrands',
  async (params, { rejectWithValue }) => {
    try {
      return await fetchCatalogBrandsWithFilter(params.catalogId, params.args);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
