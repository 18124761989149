import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { FilterInputs } from 'entries/cards';
import { getCardsHistory } from 'api/orders/getCardsHistory';
import {
  CardsHistoryModel,
  Arguments,
} from 'api/orders/getCardsHistory/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const setCardsHistorySearch = createAction<FilterInputs>(
  'cards/findCard',
);

export const fetchCardHistory = createAsyncThunk<
  CardsHistoryModel,
  Arguments,
  AsyncThunkOptions
>(
  'cardsHistory/fetchCardsHistory',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getCardsHistory(queryParams);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const saveCardsHistoryFilterStatus = createAction<boolean>(
  'cardHistory/saveCardsHistoryFilterStatus',
);
