import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, ReconciliationProcessModel } from './Models';

export const getReconciliationProcessHistory = async (
  params: Arguments,
): Promise<ReconciliationProcessModel> => {
  const response = await apiClient.get(
    `${ENDPOINTS.RECONCILIATION}/reconciliation-history`,
    { params },
  );

  return response.data;
};
