import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  Arguments as InventoryArguments,
  getProductInventory,
} from 'api/inventory/getProductInventory';
import { InventoryResponse } from 'api/inventory/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchReservedProductInventory = createAsyncThunk<
  InventoryResponse,
  { productId: string } & { params: InventoryArguments },
  AsyncThunkOptions
>(
  'inventory/fetchReservedProductInventory',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getProductInventory(
        queryParams.productId,
        queryParams.params,
      );
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const saveFilterStatusReservedProductInventory = createAction<boolean>(
  'inventory/saveFilterStatusReservedProductInventory',
);
