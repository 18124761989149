import { Catalog, CatalogsListFilterInputs } from 'entries/catalogs';
import { getCatalogsApi } from 'api/catalogs/getCatalogs';
import { Arguments, Response } from 'api/catalogs/Models';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAvailableCatalogs } from 'api/catalogs/fetchAvailableCatalogs';
import { AvailableCatalogsResponse } from 'api/catalogs/fetchAvailableCatalogs/Models';
import { fetchAvailableBrandCatalogs } from 'api/catalogs/fetchAvailableBrandCatalogs';
import { AvailableCatalogsArguments } from 'api/catalogs/fetchAvailableBrandCatalogs/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const setCatalogSearch = createAction<Partial<CatalogsListFilterInputs>>(
  'catalogs/setCatalogSearch',
);
export const setActiveCatalog = createAction<Catalog | null>(
  'catalogs/setActiveCatalog',
);

export const clearCatalogsInfo = createAction('catalogs/clearCatalogsInfo');

export const clearCatalogsErrors = createAction('catalogs/clearCatalogsErrors');
export const clearCatalogsFilters = createAction(
  'catalogs/clearCatalogsFilters',
);

export const fetchCatalogs = createAsyncThunk<
  Response,
  Arguments,
  AsyncThunkOptions
>('catalogs/fetchCatalogs', async (queryParams, { rejectWithValue }) => {
  try {
    return await getCatalogsApi(queryParams);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});

export const fetchAvailableCatalogsForProduct = createAsyncThunk<
  AvailableCatalogsResponse,
  { productId: number } & { args: Arguments },
  AsyncThunkOptions
>(
  'catalogs/fetchAvailableCatalogsForProduct',
  async (params, { rejectWithValue }) => {
    try {
      return await fetchAvailableCatalogs(params.productId, params.args);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const fetchAvailableCatalogsForBrand = createAsyncThunk<
  AvailableCatalogsResponse,
  { brandId: number } & { args: AvailableCatalogsArguments },
  AsyncThunkOptions
>(
  'catalogs/fetchAvailableCatalogsForBrand',
  async (params, { rejectWithValue }) => {
    try {
      return await fetchAvailableBrandCatalogs(params.brandId, params.args);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
