import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, CategoryModel } from './Models';

export const getCategoryList = async (
  params: Arguments,
): Promise<CategoryModel> => {
  const response = await apiClient.get(ENDPOINTS.CATEGORY, { params });
  return response.data;
};
