import { createReducer } from '@reduxjs/toolkit';

import { BrandCatalogTableState } from './Models';
import { fetchAvailableCatalogs, fetchBrandCatalog } from './actions';

export const initialState: BrandCatalogTableState = {
  brandCatalog: {
    catalogs: {
      pageSize: 0,
      pageIndex: 0,
      count: 0,
      items: [],
    },
    isLoading: true,
    error: null,
  },
  availableCatalogs: {
    catalogs: {
      pageSize: 0,
      pageIndex: 0,
      count: 0,
      items: [],
    },
    isLoading: true,
    error: null,
  },
};

export const brandCatalog = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchBrandCatalog.pending, (state) => {
      state.brandCatalog.isLoading = true;
      state.brandCatalog.error = null;
    })
    .addCase(fetchBrandCatalog.fulfilled, (state, action) => {
      state.brandCatalog.isLoading = false;
      state.brandCatalog.catalogs = action.payload;
    })
    .addCase(fetchBrandCatalog.rejected, (state, action) => {
      state.brandCatalog.isLoading = false;
      state.brandCatalog.error = action.payload || 500;
    })

    .addCase(fetchAvailableCatalogs.pending, (state) => {
      state.availableCatalogs.isLoading = true;
      state.availableCatalogs.error = null;
    })
    .addCase(fetchAvailableCatalogs.fulfilled, (state, action) => {
      state.availableCatalogs.isLoading = false;
      state.availableCatalogs.catalogs = action.payload;
    })
    .addCase(fetchAvailableCatalogs.rejected, (state, action) => {
      state.availableCatalogs.isLoading = false;
      state.availableCatalogs.error = action.payload || 500;
    });
});
