import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  Arguments,
  AvailableProductsResponse,
} from 'api/catalogs/fetchAvailableCatalogsProducts/Models';
import { fetchAvailableCatalogsProducts } from 'api/catalogs/fetchAvailableCatalogsProducts';
type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchAvailableProducts = createAsyncThunk<
  AvailableProductsResponse,
  { catalogId: string } & { args: Arguments },
  AsyncThunkOptions
>(
  'availableCatalogProducts/fetchAvailableProducts',
  async (params, { rejectWithValue }) => {
    try {
      return await fetchAvailableCatalogsProducts(
        params.catalogId,
        params.args,
      );
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const saveFilterStatusAvailableCatalogProducts = createAction<boolean>(
  'availableCatalogProducts/saveFilterStatusCatalogProducts',
);
