import { ENDPOINTS } from 'api/endpoints';
import { apiClient } from 'config/auth';
import { Arguments } from './Models';
import * as types from './Models';

export const restoreCatalogItems = async (
  params: Arguments,
): Promise<types.Response> => {
  const response = await apiClient.get(`${ENDPOINTS.RESTORE_CATALOG_ITEMS}`, {
    params,
  });

  return response.data;
};
