import { apiClient } from 'config/auth';
import { Arguments, AvailableBrandsResponse } from './Models';

export const fetchAvailableCatalogsBrands = async (
  catalogId: string,
  params: Arguments,
): Promise<AvailableBrandsResponse> => {
  const response = await apiClient.get(
    `/catalogs-brands/${catalogId}/available-brands`,
    {
      params,
    },
  );
  return response.data;
};
