import { InventoryState } from 'store/inventory/Models';
import { createReducer } from '@reduxjs/toolkit';
import {
  fetchActiveProductInventory,
  saveFilterStatusActiveProductInventory,
} from 'store/inventory/activeProductInventory/actions';

export const initialState: InventoryState = {
  inventory: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: false,
  error: null,
  isFilterActive: false,
};

export const activeProductInventory = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchActiveProductInventory.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchActiveProductInventory.fulfilled, (state, action) => {
      state.inventory = action.payload;
      state.isLoading = false;
    })
    .addCase(fetchActiveProductInventory.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    })
    .addCase(saveFilterStatusActiveProductInventory, (state, action) => {
      state.isFilterActive = action.payload;
    });
});
