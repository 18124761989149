import { CatalogClientsState } from './Models';
import { createReducer } from '@reduxjs/toolkit';
import {
  fetchClients,
  saveFilterStatusCatalogClients,
} from 'store/catalog/catalogClients/actions';

export const initialState: CatalogClientsState = {
  clients: {
    count: 0,
    items: [],
    pageIndex: 0,
    pageSize: 0,
  },
  isLoading: true,
  isFilterActive: false,
  error: null,
};

export const catalogClients = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchClients.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchClients.fulfilled, (state, action) => {
      state.clients = action.payload;
      state.isLoading = false;
    })
    .addCase(fetchClients.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    })
    .addCase(saveFilterStatusCatalogClients, (state, action) => {
      state.isFilterActive = action.payload;
    });
});
