import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import {
  AvailableCatalogsArguments,
  AvailableCatalogsResponse,
} from './Models';

export const fetchAvailableCatalogs = async (
  productId: number,
  params: AvailableCatalogsArguments,
): Promise<AvailableCatalogsResponse> => {
  const response = await apiClient.get(
    `${ENDPOINTS.PRODUCTS}/${productId}/available-catalogs`,
    {
      params,
    },
  );
  return response.data;
};
