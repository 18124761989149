import { apiClient } from 'config/auth';
import * as types from './Models';

export const fetchCatalogClients = async (
  catalogId: string | number,
  params: types.Arguments,
): Promise<types.Response> => {
  const response = await apiClient.get(`/catalogs/${catalogId}/clients`, {
    params,
  });

  return response.data;
};
