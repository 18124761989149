import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { ShoppingCart as Response } from 'api/shoppingCart/getShoppingCartById/Models';
import { Arguments } from './Models';

export const updateCommentText = async (
  id: string | number,
  body: Arguments,
): Promise<Response> => {
  const response = await apiClient.put(
    `${ENDPOINTS.SHOPPING_CART_LIST}/${id}`,
    body,
  );

  return response.data;
};
