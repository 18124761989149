import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, EnebaSelectProduct } from './Models';
import { omit } from 'lodash';

export const getProductEnebaSelectTable = async (
  params: Arguments,
): Promise<EnebaSelectProduct> => {
  const response = await apiClient.get(
    `${ENDPOINTS.ENEBA}/${params.enebaId}${ENDPOINTS.AVAILABLE_PRODUCTS}`,
    {
      params: omit(params, 'enebaId'),
    },
  );

  return response.data;
};
