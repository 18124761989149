import { ENDPOINTS } from 'api/endpoints';
import { apiClient } from 'config/auth';
import { AllItemResponse } from './Models';

type Params = {
  pageSize: number;
  pageIndex: number;
};

export const getAllOrderItem = async (
  orderId: string | number,
  params: Params,
): Promise<AllItemResponse> => {
  const response = await apiClient.get(
    `${ENDPOINTS.ORDERS}/${orderId}/orderItems-info`,
    {
      params,
    },
  );

  return response.data;
};
