import { createAsyncThunk } from '@reduxjs/toolkit';
import { G2aCategory, Arguments } from '../../../api/g2a/getG2aCategory/Models';
import { getG2aSelectCategoryTable } from 'api/g2a/getG2aCategory';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchG2aCategoryTable = createAsyncThunk<
  G2aCategory,
  Arguments,
  AsyncThunkOptions
>(
  'eneba/fetchSelectCategoryTable',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getG2aSelectCategoryTable(queryParams);
    } catch (error) {
      console.error(error.response.data);
      return rejectWithValue(error.response.status);
    }
  },
);
