import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAvailableBrands } from 'api/brands/supplierMapping/getAvailableBrands';
import { getBrandSupplierMapping } from 'api/brands/supplierMapping/getSupplierMapping';
import {
  Arguments as SupplierMappingFilterInputs,
  SupplierMappingModel,
} from 'api/brands/supplierMapping/getSupplierMapping/Models';

import { Arguments as AvailableBrandsFilterInputs } from 'api/brands/supplierMapping/getAvailableBrands/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchBrandSupplierMapping = createAsyncThunk<
  SupplierMappingModel,
  { brandId: number } & { params: SupplierMappingFilterInputs },
  AsyncThunkOptions
>(
  'brand/fetchBrandSupplierMapping',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getBrandSupplierMapping(
        queryParams.brandId,
        queryParams.params,
      );
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const fetchAvailableBrands = createAsyncThunk<
         SupplierMappingModel,
         { brandId: number } & { args: AvailableBrandsFilterInputs },
         AsyncThunkOptions
       >('brand/fetchAvailableBrands', async (params, { rejectWithValue }) => {
         try {
           return await getAvailableBrands(params.brandId, params.args);
         } catch (error) {
           return rejectWithValue(error.response.status);
         }
       });

