export enum BinanceRequestListFilterField {
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  Status = 'status',
  ClientEmail = 'clientEmail',
  PrePayId = 'prepayId',
  CustomerReferenceIdentifier = 'cutomerReferenceIdentifier',
  HasOrder = 'hasOrder',
}

export interface FilterInputs {
  [BinanceRequestListFilterField.DateFrom]: string;
  [BinanceRequestListFilterField.DateTo]: string;
  [BinanceRequestListFilterField.Status]: string;
  [BinanceRequestListFilterField.ClientEmail]: string;
  [BinanceRequestListFilterField.PrePayId]: string;
  [BinanceRequestListFilterField.CustomerReferenceIdentifier]: string;
  [BinanceRequestListFilterField.HasOrder]: string;
}
