import { Arguments } from 'api/catalogs/fetchCatalogClients/Models';
import { fetchCatalogClients } from 'api/catalogs/fetchCatalogClients';
import { Response } from 'api/catalogs/fetchCatalogClients/Models';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchClients = createAsyncThunk<
  Response,
  { catalogId: string } & { args: Arguments },
  AsyncThunkOptions
>('catalogClients/fetchClients', async (params, { rejectWithValue }) => {
  try {
    return await fetchCatalogClients(params.catalogId, params.args);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});

export const saveFilterStatusCatalogClients = createAction<boolean>(
  'catalogClients/saveFilterStatusCatalogClients',
);
