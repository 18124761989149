import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import {
  SupplierLiveCatalogArguments,
  SupplierLiveCatalogResponse,
} from './Models';

export const getSupplierLiveCatalogs = async (
  supplierCode: string,
  params: SupplierLiveCatalogArguments,
): Promise<SupplierLiveCatalogResponse> => {
  const response = await apiClient.get(
    `${ENDPOINTS.SUPPLIERS}/${supplierCode}/supplier-live-catalogs`,
    { params },
  );
  return response.data;
};
