import { ENDPOINTS } from 'api/endpoints';
import { apiClient } from 'config/auth';
import { Arguments, Response } from '../Models';

export const getInventoryReports = async (
  params: Arguments,
): Promise<Response> => {
  const response = await apiClient.get(
    `${ENDPOINTS.INVENTORY_REPORTS}/fetch-report`,
    { params },
  );
  return response.data;
};
