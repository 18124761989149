import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { OrderHistoryDetails } from './Models';

export const getOrderHistoryDetails = async (
  orderId: string,
  isAdmin?: boolean,
  isFinanceManager?: boolean,
  isCustomerSupport?: boolean,
  isSales?: boolean,
  isMarketplaceManager?: boolean,
): Promise<OrderHistoryDetails> => {
  const requestEndpoint =
    isAdmin ||
    isFinanceManager ||
    isCustomerSupport ||
    isSales ||
    isMarketplaceManager
      ? ENDPOINTS.ORDERS
      : ENDPOINTS.ORDERS_CLIENT;
  const response = await apiClient.get(`${requestEndpoint}/${orderId}`);

  return response.data;
};
