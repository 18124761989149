import { createReducer } from '@reduxjs/toolkit';
import { CatalogProductsState } from './Models';
import { fetchEditableProducts } from 'store/catalog/editableCatalogProducts/actions';
import { clearCatalogsErrors } from 'store/catalogs/actions';

export const initialState: CatalogProductsState = {
  count: 0,
  items: [],
  pageIndex: 0,
  pageSize: 0,
  errorState: {
    status: null,
    message: '',
  },
  isLoading: true,
  error: null,
};

export const editableCatalogProducts = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(fetchEditableProducts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchEditableProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.count = action.payload.count;
        state.pageSize = action.payload.pageSize;
        state.pageIndex = action.payload.pageIndex;
        state.items = action.payload.items.map((product) => ({
          ...product,
          isValid: true,
          tableData: {
            checked: false,
          },
        }));
      })
      .addCase(fetchEditableProducts.rejected, (state, action) => {
        state.error = action.payload || 500;
        state.isLoading = false;
      })
      .addCase(clearCatalogsErrors, (state) => {
        state.error = null;
      });
  },
);
