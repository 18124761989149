import { makeStyles } from '@material-ui/core/styles';
import { styledComponentTheme } from 'modules/theme/styledComponentTheme';

export const useClasses = makeStyles(() => ({
  preloaderWrap: {
    position: 'fixed',
    width: `calc(100% - ${styledComponentTheme.sidebarWidth})`,
    height: '100%',
    right: '0',
    top: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  },
  preloader: {
    width: '80px',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  preloaderWrapForTable: {
    width: '100%',
    height: '100%',
    right: '0',
    top: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: styledComponentTheme.colors.preloaderOpacity,
  },
}));
