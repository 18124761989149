import { createAsyncThunk } from '@reduxjs/toolkit';
import { getReconciliationAccount } from 'api/reconciliation';
import { getDiscrepancyByOrders } from 'api/reconciliation/getOrdersDiscrepancy';
import {
  ReconciliationModel,
  Arguments,
  DiscrepancyByOrdersModel,
} from '../../api/reconciliation/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchReconciliationAccounts = createAsyncThunk<
  ReconciliationModel,
  Arguments,
  AsyncThunkOptions
>(
  'reconciliation/fetchReconciliationAccounts',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getReconciliationAccount(queryParams);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const fetchDiscrepancy = createAsyncThunk<
  DiscrepancyByOrdersModel,
  Arguments,
  AsyncThunkOptions
>(
  'reconciliation/fetchDisrepancy',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getDiscrepancyByOrders(queryParams);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
