export enum NewProductField {
  SKU = 'sku',
  Name = 'name',
  Denomination = 'faceValue',
  Brand = 'brandId',
  SupplierCurrency = 'supplierCurrencyCode',
}

export interface ProductInputs {
  [NewProductField.SKU]: string;
  [NewProductField.Name]: string;
  [NewProductField.Denomination]: string | number;
  [NewProductField.Brand]: string | number;
  [NewProductField.SupplierCurrency]: string;
}
