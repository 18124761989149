import { createReducer } from '@reduxjs/toolkit';
import { Response as StoreState } from 'api/catalogs/fetchCatalogProducts/Models';
import {
  fetchInactiveProducts,
  saveInactiveFilterStatus,
  setInitialInactiveCatalog,
} from 'store/catalog/inactiveCatalogProducts/actions';
import { clearCatalogsErrors } from 'store/catalogs/actions';

type State = StoreState & {
  error: number | null;
  isLoading: boolean;
  isFilterActive: boolean;
};

export const initialState: State = {
  count: 0,
  items: [],
  pageIndex: 0,
  pageSize: 0,
  error: null,
  isLoading: true,
  isFilterActive: false,
};

export const inactiveCatalogProducts = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(fetchInactiveProducts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchInactiveProducts.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          isLoading: false,
        };
      })
      .addCase(fetchInactiveProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 500;
      })
      .addCase(saveInactiveFilterStatus, (state, action) => {
        state.isFilterActive = action.payload;
      })
      .addCase(clearCatalogsErrors, (state) => {
        state.error = null;
      })
      .addCase(setInitialInactiveCatalog, (state) => {
        state.count = initialState.count;
        state.items = initialState.items;
        state.pageSize = initialState.pageSize;
        state.pageIndex = initialState.pageIndex;
        state.error = initialState.error;
        state.isLoading = initialState.isLoading;
        state.isFilterActive = initialState.isFilterActive;
      });
  },
);
