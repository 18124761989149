import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEnebaAccountList } from 'api/eneba/getEnebaAccountList';
import { EnebaAccountList } from 'api/eneba/getEnebaAccountList/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchEnebaAccountList = createAsyncThunk<
  EnebaAccountList[],
  void,
  AsyncThunkOptions
>('eneba/fetchEnebaAccountList', async (_, { rejectWithValue }) => {
  try {
    return await getEnebaAccountList();
  } catch (error) {
    if (error.response && error.response.status >= 500) {
      console.error({
        message: error.response.data.message,
        status: error.response.status,
        statusText: error.response.statusText,
      });
    }
    return rejectWithValue(error.response.status);
  }
});
