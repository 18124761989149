import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  EnebaSelectProduct,
  Arguments,
} from '../../../api/eneba/getAvailableProduct/Models';
import { getProductEnebaSelectTable } from '../../../api/eneba/getAvailableProduct';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchEnebaProductSelectTable = createAsyncThunk<
  EnebaSelectProduct,
  Arguments,
  AsyncThunkOptions
>('eneba/fetchSelectProductTable', async (queryParams, { rejectWithValue }) => {
  try {
    return await getProductEnebaSelectTable(queryParams);
  } catch (error) {
    if (error.response && error.response.status >= 500) {
      console.error({
        message: error.response.data.message,
        status: error.response.status,
        statusText: error.response.statusText,
      });
    }
    return rejectWithValue(error.response.status);
  }
});
