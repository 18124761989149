import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProductEnebaMainTable } from '../../../api/eneba/getProductEnebaMainTable';
import {
  EnebaProductsMainTableModel,
  Arguments,
} from '../../../api/eneba/getProductEnebaMainTable/Models';
import { getReservationStatus } from 'api/eneba/getReservationStatus';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchEnebaProductMainTable = createAsyncThunk<
  EnebaProductsMainTableModel,
  Arguments,
  AsyncThunkOptions
>(
  'eneba/fetchMainTableProductEneba',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getProductEnebaMainTable(queryParams);
    } catch (error) {
      if (error.response && error.response.status >= 500) {
        console.error({
          message: error.response.data.message,
          status: error.response.status,
          statusText: error.response.statusText,
        });
      }
      return rejectWithValue(error.response.status);
    }
  },
);

export const fetchEnebaReservationStatus = createAsyncThunk<
  boolean,
  string,
  AsyncThunkOptions
>(
  'eneba/fetchEnebaReservationStatus',
  async (accountId, { rejectWithValue }) => {
    try {
      const result = await getReservationStatus(accountId);
      return result;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
