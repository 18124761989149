import { createAsyncThunk } from '@reduxjs/toolkit';
import { AllItemResponse } from '../../../api/orders/getAllOrderItem/Models';
import { getAllOrderItem } from '../../../api/orders/getAllOrderItem';

type Params = {
  pageSize: number;
  pageIndex: number;
};

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchAllOrderItemById = createAsyncThunk<
  AllItemResponse,
  { id: string | number } & { params: Params },
  AsyncThunkOptions
>(
  'orders/fetchAllOrderItemById',
  async ({ id, params }, { rejectWithValue }) => {
    try {
      return await getAllOrderItem(id, params);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
