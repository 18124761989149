import { createReducer } from '@reduxjs/toolkit';

import { GamivoSelectTableState } from './Models';
import { fetchGamivoInternalProduct } from './actions';

export const initialState: GamivoSelectTableState = {
  gamivoSelectProduct: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: true,
  isFilterActive: false,
  error: null,
};

export const gamivoSelectTableProduct = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(fetchGamivoInternalProduct.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchGamivoInternalProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.gamivoSelectProduct = action.payload;
      })
      .addCase(fetchGamivoInternalProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 500;
      });
  },
);
