export enum InventoryCardsFilterField {
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  Brand = 'brandId',
  Product = 'productId',
  Client = 'clientId',
  Supplier = 'supplierId',
  CardStatus = 'cardStatus',
  FilterBy = 'filterBy',
  FilterValue = 'filterValue',
  CardCode = 'cardCode',
  CardUrl = 'url',
  CodeFileReference = 'codeFileReference',
  Denomination = 'denomination',
  ExpiringDate = 'expiringDate',
  SupplierInvoiceNumber = 'supplierInvoiceNumber',
}

export interface FilterInputs {
  [InventoryCardsFilterField.DateFrom]: string;
  [InventoryCardsFilterField.DateTo]: string;
  [InventoryCardsFilterField.Brand]: string;
  [InventoryCardsFilterField.Product]: string;
  [InventoryCardsFilterField.FilterBy]: string;
  [InventoryCardsFilterField.FilterValue]: string;
  [InventoryCardsFilterField.Client]: string;
  [InventoryCardsFilterField.Supplier]: string;
  [InventoryCardsFilterField.CardStatus]: string;
  [InventoryCardsFilterField.CardCode]: string;
  [InventoryCardsFilterField.CardUrl]: string;
  [InventoryCardsFilterField.CodeFileReference]?: string;
  [InventoryCardsFilterField.Denomination]?: string;
  [InventoryCardsFilterField.ExpiringDate]: string;
  [InventoryCardsFilterField.SupplierInvoiceNumber]?: string;
}
