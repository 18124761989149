import { createReducer } from '@reduxjs/toolkit';
import { BrandCatalogsFilterField } from 'entries/catalogs/filters/brandCatalogsFilter';
import {
  fetchBrandCatalogs,
  setBrandCatalogs,
  setBrandCatalogsSearch,
} from './actions';
import { BrandCatalogsState } from './Models';

export const initialState: BrandCatalogsState = {
  count: 0,
  items: [],
  pageIndex: 0,
  pageSize: 0,
  isLoading: true,
  error: null,
  brandCatalogsFilter: {
    [BrandCatalogsFilterField.Search]: '',
    [BrandCatalogsFilterField.IsIntegrated]: true,
  },
};

export const brandCatalogs = createReducer(initialState, (builder) => {
  builder
    .addCase(setBrandCatalogs, (state, action) => {
      state.items = action.payload;
    })
    .addCase(fetchBrandCatalogs.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchBrandCatalogs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.count = action.payload.count;
      state.pageSize = action.payload.pageSize;
      state.pageIndex = action.payload.pageIndex;
      state.items = action.payload.items.map((catalog) => ({
        ...catalog,
        tableData: {
          checked: false,
        },
      }));
    })
    .addCase(fetchBrandCatalogs.rejected, (state, action) => {
      state.error = action.payload || 500;
      state.isLoading = false;
    })
    .addCase(setBrandCatalogsSearch, (state, action) => {
      state.brandCatalogsFilter = action.payload;
    });
});
