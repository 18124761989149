import { createReducer } from '@reduxjs/toolkit';
import { fetchNonEditableIntegratedProducts } from 'store/products/editableNonIntegratedProducts/actions';

import { EditableNonIntegratedProductsState } from './Models';

export const initialState: EditableNonIntegratedProductsState = {
  count: 0,
  items: [],
  pageIndex: 0,
  pageSize: 0,
  isLoading: true,
  error: null,
};

export const editableNonIntegratedProducts = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(fetchNonEditableIntegratedProducts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchNonEditableIntegratedProducts.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.count = action.payload.count;
          state.pageSize = action.payload.pageSize;
          state.pageIndex = action.payload.pageIndex;
          state.items = action.payload.items.map((product) => ({
            ...product,
            isValid: true,
            tableData: {
              checked: false,
            },
          }));
        },
      )
      .addCase(fetchNonEditableIntegratedProducts.rejected, (state, action) => {
        state.error = action.payload || 500;
        state.isLoading = false;
      });
  },
);
