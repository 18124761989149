import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, G2aCategory } from './Models';

export const getG2aSelectCategoryTable = async (
  params: Arguments,
): Promise<G2aCategory> => {
  const response = await apiClient.get(
    `${ENDPOINTS.G2A}${ENDPOINTS.UNMAPPED_PRODUCT}`,
    {
      params,
    },
  );

  return response.data;
};
