import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import {
  ProductConfigurationModel,
  ProductConfigurations,
} from 'api/suppliers/Models';

type Params = {
  pageSize: number;
  pageIndex: number;
};

export const getSupplierProductConfigurations = async (
         supplierId: string | number,
         params: Params,
       ): Promise<ProductConfigurationModel> => {
         const response = await apiClient.get(
           `${ENDPOINTS.SUPPLIERS}/${supplierId}/product-configurations`,
           { params },
         );

         return response.data;
       };
