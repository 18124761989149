import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, DiscrepancyByOrdersModel } from './Models';

export const getDiscrepancyByOrders = async (
  params: Arguments,
): Promise<DiscrepancyByOrdersModel> => {
  const response = await apiClient.get(
    `${ENDPOINTS.TRANSACTION_REPORT}/discrepancy-by-orders`,
    { params },
  );

  return response.data;
};
