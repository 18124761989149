import {
  Arguments,
  CatalogBrandsResponse,
} from 'api/bambooCatalogs/fetchCatalogBrandsWithFilter/Models';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCatalogBrandsWithFilter } from 'api/bambooCatalogs/fetchCatalogBrandsWithFilter';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchBrands = createAsyncThunk<
  CatalogBrandsResponse,
  { catalogId: string } & { args: Arguments },
  AsyncThunkOptions
>('catalogBrands/fetchBrands', async (params, { rejectWithValue }) => {
  try {
    return await fetchCatalogBrandsWithFilter(params.catalogId, params.args);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});

export const saveFilterStatusCatalogBrands = createAction<boolean>(
  'catalogBrands/saveFilterStatusCatalogBrands',
);

export const setInitialCatalog = createAction(
  'catalogBrands/setInitialCatalog',
);
