import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Arguments,
  CategoryBrandModel,
} from 'api/categories/getCategoryBrands/Models';
import { getCategoryBrands } from 'api/categories/getCategoryBrands';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchCategoryBrands = createAsyncThunk<
  CategoryBrandModel,
  Arguments,
  AsyncThunkOptions
>('category/fetchCategoryBrands', async (queryParams, { rejectWithValue }) => {
  try {
    return await getCategoryBrands(queryParams);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});
