import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderByStatusResponse } from '../../../api/orders/getOrderByStatus/Models';
import { getOrderByStatus } from '../../../api/orders/getOrderByStatus';
import { boolean } from 'yup';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchOrderByStatus = createAsyncThunk<
         OrderByStatusResponse,
         { id: string | number } & { isAdmin: boolean } & {
           isFinanceManager?: boolean;
         } & { isCustomerSupport?: boolean } & { isSales?: boolean },
         AsyncThunkOptions
       >(
         'orders/fetchOrderGroupedByStatus',
         async (orderData, { rejectWithValue }) => {
           try {
             return await getOrderByStatus(
               orderData.id,
               orderData.isAdmin,
               orderData?.isFinanceManager,
               orderData?.isCustomerSupport,
               orderData?.isSales,
             );
           } catch (error) {
             return rejectWithValue(error.response.status);
           }
         },
       );
