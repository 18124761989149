export enum ClientCatalogProductsFilterField {
  Search = 'searchText',
  Country = 'country',
  Currency = 'currency',
  Brand = 'brandId',
}

export interface ClientCatalogProductsFilterInputs {
  [ClientCatalogProductsFilterField.Search]: string;
  [ClientCatalogProductsFilterField.Country]: string;
  [ClientCatalogProductsFilterField.Currency]: string;
  [ClientCatalogProductsFilterField.Brand]: string;
}
