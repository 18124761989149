import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { BrandInfoWithProducts } from 'entries/brands';

export const getBrandById = async (
  id: string | number,
): Promise<BrandInfoWithProducts> => {
  const response = await apiClient.get(`${ENDPOINTS.BRANDS_LIST}${id}`);

  return response.data;
};
