import { ShoppingCartsState } from './Models';
import { createReducer } from '@reduxjs/toolkit';
import {
  changeCommentText,
  deleteProduct,
  fetchShoppingCartById,
  fetchShoppingCartsList,
  resetActiveShoppingCart,
  resetActiveShoppingCartError,
  saveShoppingCartsFilterStatus,
  setShoppingCartSearch,
  updateProduct,
} from 'store/shoppingCart/actions';
import { ShoppingCartsListFilterField } from 'entries/shoppingCart/shoppingCartsListFilters';

export const initialState: ShoppingCartsState = {
  filter: {
    [ShoppingCartsListFilterField.BrandName]: '',
    [ShoppingCartsListFilterField.ClientId]: '',
    [ShoppingCartsListFilterField.CurrencyCode]: '',
  },
  shoppingCartsList: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isFilterActive: false,
  activeShoppingCart: {
    isLoading: true,
    shoppingCart: null,
  },
  isLoading: true,
  errors: {
    shoppingCartsList: null,
    activeShoppingCart: null,
  },
};

export const shoppingCarts = createReducer(initialState, (builder) => {
  builder
    .addCase(setShoppingCartSearch, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(fetchShoppingCartsList.pending, (state) => {
      state.isLoading = true;
      state.errors.shoppingCartsList = null;
    })
    .addCase(fetchShoppingCartsList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.shoppingCartsList = action.payload;
    })
    .addCase(fetchShoppingCartsList.rejected, (state, action) => {
      state.isLoading = false;
      state.errors.shoppingCartsList = action.payload || 500;
    })
    .addCase(fetchShoppingCartById.pending, (state) => {
      state.activeShoppingCart.isLoading = true;
      state.errors.activeShoppingCart = null;
    })
    .addCase(fetchShoppingCartById.fulfilled, (state, action) => {
      state.activeShoppingCart.isLoading = false;
      state.activeShoppingCart.shoppingCart = action.payload;
    })
    .addCase(fetchShoppingCartById.rejected, (state, action) => {
      state.activeShoppingCart.isLoading = false;
      state.errors.activeShoppingCart = action.payload || 500;
    })
    .addCase(saveShoppingCartsFilterStatus, (state, action) => {
      state.isFilterActive = action.payload;
    })
    .addCase(updateProduct, (state, action) => {
      if (state.activeShoppingCart.shoppingCart) {
        state.activeShoppingCart.shoppingCart.suppliers = state.activeShoppingCart.shoppingCart.suppliers.map(
          (supplier) => {
            const productIndex = supplier.products.findIndex(
              (oldProduct) => oldProduct.id === action.payload.id,
            );
            supplier.products[productIndex] = action.payload;

            return supplier;
          },
        );
      } else return state;
    })
    .addCase(deleteProduct, (state, action) => {
      if (state.activeShoppingCart.shoppingCart) {
        state.activeShoppingCart.shoppingCart.suppliers = state.activeShoppingCart.shoppingCart.suppliers.filter(
          (supplier) => {
            supplier.products = supplier.products.filter(
              (oldProduct) => oldProduct.id !== action.payload.id,
            );
            return supplier.products.length;
          },
        );
      } else return state;
    })
    .addCase(changeCommentText, (state, action) => {
      if (state.activeShoppingCart.shoppingCart) {
        state.activeShoppingCart.shoppingCart.notes = action.payload;
      } else return state;
    })
    .addCase(resetActiveShoppingCart, (state) => {
      if (state.activeShoppingCart.shoppingCart) {
        state.activeShoppingCart.shoppingCart = null;
      } else return state;
    })
    .addCase(resetActiveShoppingCartError, (state) => {
      state.errors.activeShoppingCart = null;
    });
});
