import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, G2aProductsMainTableModel } from './Models';
import axios from 'axios';

export const getProductG2aMainTable = async (
  params: Arguments,
): Promise<G2aProductsMainTableModel> => {
  const response = await apiClient.get(
    `${ENDPOINTS.G2A}${ENDPOINTS.PRODUCTS}`,
    { params },
  );

  return response.data;
};
