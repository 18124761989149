import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  EnebaCategory,
  Arguments,
} from '../../../api/eneba/getEnebaCategory/Models';
import { getEnebaSelectCategoryTable } from '../../../api/eneba/getEnebaCategory';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchEnebaCategoryTable = createAsyncThunk<
  EnebaCategory,
  Arguments,
  AsyncThunkOptions
>(
  'eneba/fetchSelectCategoryTable',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getEnebaSelectCategoryTable(queryParams);
    } catch (error) {
      if (error.response && error.response.status >= 500) {
        console.error({
          message: error.response.data.message,
          status: error.response.status,
          statusText: error.response.statusText,
        });
      }
      return rejectWithValue(error.response.status);
    }
  },
);
