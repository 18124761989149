import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, SupplierMappingModel } from './Models';

export const getAvailableBrands = async (
  brandId: number,
  params: Arguments,
): Promise<SupplierMappingModel> => {
  const response = await apiClient.get(
    `${ENDPOINTS.BRANDS_LIST}${brandId}/available-brands`,
    { params },
  );
  return response.data;
};
