import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, GamivoSelectProduct } from './Models';

export const getProductGamivoSelectTable = async (
  params: Arguments,
): Promise<GamivoSelectProduct> => {
  const response = await apiClient.get(
    ENDPOINTS.GAMIVO + ENDPOINTS.AVAILABLE_PRODUCTS,
    {
      params,
    },
  );

  return response.data;
};
