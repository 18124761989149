import { apiClient } from 'config/auth';
import { Arguments, Response } from './Models';

export const fetchAvailableCatalogClients = async (
  catalogId: string,
  params: Arguments,
): Promise<Response> => {
  const response = await apiClient.get(
    `/Catalogs/${catalogId}/available-clients`,
    {
      params: {
        ...params,
      },
    },
  );

  return response.data;
};
