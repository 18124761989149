import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { Arguments, Response } from 'api/products/Models';
import { getProductsApi } from 'api/products/getProducts';
import { FilterInputs } from 'entries/products';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const setIntegratedProductsSearch = createAction<FilterInputs>(
  'productss/findIntegratedProducts',
);

export const fetchIntegratedProducts = createAsyncThunk<
  Response,
  Arguments,
  AsyncThunkOptions
>(
  'products/fetchIntegratedProducts',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getProductsApi({ ...queryParams, isIntegrated: true });
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const saveIntegratedProductsFilterStatus = createAction<boolean>(
  'catalogProducts/saveIntegratedProductsFilterStatus',
);
