import { createReducer } from '@reduxjs/toolkit';

import { State } from './Models';
import { fetchGamivoEditableProduct } from './actions';

export const initialState: State = {
  editableProduct: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: true,
  isFilterActive: false,
  error: null,
};

export const gamivoEditableProduct = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchGamivoEditableProduct.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchGamivoEditableProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.editableProduct = action.payload;
    })
    .addCase(fetchGamivoEditableProduct.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    });
});
