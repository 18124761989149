import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Arguments, Response } from 'api/catalogs/fetchClientCatalog/Models';
import { fetchClientCatalog as fetchClientCatalogApi } from 'api/catalogs/fetchClientCatalog';

type AsyncThunkOptions = {
  rejectValue: number;
};

// Action to reset the client catalog
export const resetClientCatalog = createAction('clientCatalog/resetClientCatalog');

export const fetchClientCatalog = createAsyncThunk<
  Response,
  Arguments,
  AsyncThunkOptions
>('clientCatalog/fetchClientCatalog', async (params, { rejectWithValue }) => {
  try {
    return await fetchClientCatalogApi(params);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});

export const saveFilterStatusClientCatalog = createAction<boolean>(
  'clientCatalog/saveFilterStatusClientCatalog',
);
