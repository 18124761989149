import jwtDecode from 'jwt-decode';

interface TokenDTO {
  auth_time: number;
  client_id: string;
  'cognito:groups': Array<
    | 'Admin'
    | 'User'
    | 'FinanceManager'
    | 'CustomerSupport'
    | 'Sales'
    | 'OperationsExecutive'
    | 'AccountManager'
    | 'MarketplaceManager'
    | 'Tech'
  >;
  event_id: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  scope: string;
  sub: string;
  token_use: string;
  username: string;
}

export const parseJwt = (token: string): TokenDTO => {
  return jwtDecode<TokenDTO>(token);
};
