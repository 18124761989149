import { createReducer } from '@reduxjs/toolkit';

import { EnebaMainTableState } from './Models';
import {
  fetchEnebaProductMainTable,
  fetchEnebaReservationStatus,
} from './actions';

export const initialState: EnebaMainTableState = {
  enebaProduct: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: true,
  isFilterActive: false,
  error: null,
  enebaReservationStatus: false,
};

export const enebaMainTableProduct = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchEnebaProductMainTable.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchEnebaProductMainTable.fulfilled, (state, action) => {
      state.isLoading = false;
      state.enebaProduct = action.payload;
    })
    .addCase(fetchEnebaProductMainTable.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    })
    .addCase(fetchEnebaReservationStatus.fulfilled, (state, action) => {
      state.enebaReservationStatus = action.payload;
    });
});
