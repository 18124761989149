import { OrderHistoryDetails } from 'api/orders/getOrderHistoryDetails/Models';
import { getOrderHistoryDetails } from 'api/orders/getOrderHistoryDetails';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getMarketplaceOrderById } from 'api/orders/getMarketplaceOrder';
import { MarketplaceOrder } from 'api/orders/getMarketplaceOrder/Models';
import { IntegrationType } from 'entries/clientsList/integrationType';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const resetActiveOrderError = createAction(
  'orders/resetActiveOrderError',
);

export const updateOrderInformation = createAction<OrderHistoryDetails>(
  'orders/updateOrderInformation',
);

export const fetchOrderInfoById = createAsyncThunk<
  OrderHistoryDetails,
  { isAdmin?: boolean } & { orderId: string } & {
    isFinanceManager?: boolean;
  } & { isCustomerSupport?: boolean } & { isSales?: boolean } & {
    isMarketplaceManager?: boolean;
  },
  AsyncThunkOptions
>(
  'orders/fetchOrderInfoById',
  async (
    {
      orderId,
      isAdmin,
      isFinanceManager,
      isCustomerSupport,
      isSales,
      isMarketplaceManager,
    },
    { rejectWithValue },
  ) => {
    try {
      return await getOrderHistoryDetails(
        orderId,
        isAdmin,
        isFinanceManager,
        isCustomerSupport,
        isSales,
        isMarketplaceManager,
      );
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const fetchMarketplaceOrderById = createAsyncThunk<
  MarketplaceOrder,
  { orderId: string } & { integrationType: IntegrationType },
  AsyncThunkOptions
>(
  'orders/fetchMarketplaceOrderById',
  async ({ orderId, integrationType }, { rejectWithValue }) => {
    try {
      return await getMarketplaceOrderById(orderId, integrationType);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
