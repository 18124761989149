import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Arguments as BrandCatalogFilterInputs,
  BrandCatalogModel,
} from 'api/brands/brandCatalog/getBrandCatalog/Models';

import {
  Arguments as CatalogsListFilterInputs,
  AvailableCatalogModel,
} from 'api/brands/brandCatalog/getAvailableCatalogs/Models';
import { getBrandCatalog } from 'api/brands/brandCatalog/getBrandCatalog';
import { getAvailableCatalogs } from 'api/brands/brandCatalog/getAvailableCatalogs';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchBrandCatalog = createAsyncThunk<
  BrandCatalogModel,
  { brandId: number } & { params: BrandCatalogFilterInputs },
  AsyncThunkOptions
>('brand/fetchBrandCatalog', async (queryParams, { rejectWithValue }) => {
  try {
    return await getBrandCatalog(queryParams.brandId, queryParams.params);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});


export const fetchAvailableCatalogs = createAsyncThunk<
  AvailableCatalogModel,
  CatalogsListFilterInputs,
  AsyncThunkOptions
>('brand/fetchAvailableCatalogs', async (queryParams, { rejectWithValue }) => {
  try {
    return await getAvailableCatalogs(queryParams);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});
