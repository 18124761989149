import { createReducer } from '@reduxjs/toolkit';

import { G2aMainTableState } from './Models';
import { fetchG2aProductMainTable } from './actions';

export const initialState: G2aMainTableState = {
  g2aProduct: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: true,
  isFilterActive: false,
  error: null,
};

export const g2aMainTableProduct = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchG2aProductMainTable.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchG2aProductMainTable.fulfilled, (state, action) => {
      state.isLoading = false;
      state.g2aProduct = action.payload;
    })
    .addCase(fetchG2aProductMainTable.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    });
});
