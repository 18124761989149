import { createReducer } from '@reduxjs/toolkit';
import { fetchInventoryInvoices } from './actions';
import { InventoryInvoiceState } from './Models';

export const initialState: InventoryInvoiceState = {
  inventoryInvoice: {
    pageSize: 10,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: true,
  error: null,
};

export const inventoryInvoices = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchInventoryInvoices.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchInventoryInvoices.fulfilled, (state, action) => {
      state.inventoryInvoice = action.payload;
      state.isLoading = false;
    })
    .addCase(fetchInventoryInvoices.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    });
});
