import { generatePath, Link } from 'react-router-dom';
import { routes } from '../../routes/routes';
import { ReactComponent as BambooLogo } from '../assets/svg/newLogo.svg';
import Vector from '../assets/svg/maintenanceVector.png';
import MaintenanceImg from '../assets/svg/maintenanceImg.png';
import React from 'react';
import { useClasses } from './style';

const MaintenanceForm = () => {
  const classes = useClasses();

  return (
    <div className={classes.body}>
      <div className={classes.logo}>
        <Link to={generatePath(routes.index)} className={classes.logoLink}>
          <BambooLogo />
        </Link>
      </div>
      <div className={classes.mainContent}>
        <div className={classes.text}>
          <p>
            Bamboo-card is currently <br /> down for maintenance.
          </p>
        </div>
        <div className={classes.img}>
          <img src={Vector} alt="Vector" className={classes.vectorPng} />
          <img
            src={MaintenanceImg}
            alt="ImgMaintenance"
            className={classes.mainImg}
          />
        </div>
      </div>
    </div>
  );
};

export default MaintenanceForm;
