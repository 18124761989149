import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { UsersListResponse } from 'api/users/Models';
import { Arguments, fetchUsersList } from 'api/users/getUsersList';
import { User, UserStatuses } from 'entries/users';
import { getMyDetails } from 'api/users/getMyDetails';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const getActiveUser = createAsyncThunk<User, void, AsyncThunkOptions>(
  'users/getActiveUser',
  async (_, { rejectWithValue }) => {
    try {
      return await getMyDetails();
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getUsersList = createAsyncThunk<
  UsersListResponse,
  Arguments,
  AsyncThunkOptions
>('users/getUsersList', async (queryParams, { rejectWithValue }) => {
  try {
    return await fetchUsersList(queryParams);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});

export const deleteUser = createAction<string | number>('users/deleteUser');
export const addNewUserToList = createAction<User>('users/addNewUserToList');
export const changeUserStatus = createAction<{
  id: string | number;
  status: UserStatuses;
}>('users/changeUserStatus');

export const updateUserInList = createAction<User>('users/updateUserInList');
export const updateCurrentUser = createAction<User>('users/updateCurrentUser');
export const removeCurrentUser = createAction('users/removeCurrentUser');
