import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Arguments, Response } from 'api/catalogs/fetchCatalogProducts/Models';
import { fetchCatalogProducts } from 'api/catalogs/fetchCatalogProducts';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchInactiveProducts = createAsyncThunk<
  Response,
  { catalogId: string } & { args: Arguments },
  AsyncThunkOptions
>(
  'inactiveCatalogProducts/fetchInactiveProducts',
  async (params, { rejectWithValue }) => {
    try {
      return await fetchCatalogProducts(params.catalogId, params.args);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const saveInactiveFilterStatus = createAction<boolean>(
  'inactiveCatalogProducts/saveInactiveFilterStatus',
);

export const setInitialInactiveCatalog = createAction(
  'inactiveCatalogProducts/setInitialInactiveCatalog',
);
