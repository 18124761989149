import { InventoryReportsState } from './Models';
import { createReducer } from '@reduxjs/toolkit';
import { InventoryReportsFilterField } from 'entries/inventoryReports';
import {
  clearInventoryReportsErrors,
  clearInventoryReportsFilters,
  fetchInventoryReports,
  setInventoryReportsSearch,
} from './actions';
import moment from 'moment';

const dateTo = new Date();
const initialDateTo = moment(dateTo);
const initialDateFrom = moment(
  new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate() - 2),
);

export const initialState: InventoryReportsState = {
  reports: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  filter: {
    [InventoryReportsFilterField.SupplierName]: '',
    [InventoryReportsFilterField.InvoiceNumber]: '',
    [InventoryReportsFilterField.CodeFileReference]: '',
    [InventoryReportsFilterField.CreatedDateFrom]: initialDateFrom.toString(),
    [InventoryReportsFilterField.CreatedDateTo]: initialDateTo.toString(),
  },
  isLoading: false,
  error: null,
};

export const inventoryReports = createReducer(initialState, (builder) => {
  builder
    .addCase(setInventoryReportsSearch, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(clearInventoryReportsFilters, (state) => {
      state.filter = initialState.filter;
    })
    .addCase(clearInventoryReportsErrors, (state) => {
      state.error = null;
    })
    .addCase(fetchInventoryReports.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchInventoryReports.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reports = action.payload;
    })
    .addCase(fetchInventoryReports.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    });
});
