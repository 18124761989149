import { ENDPOINTS } from 'api/endpoints';
import { apiClient } from 'config/auth';
import { Arguments } from '../restoreCatalogItems/Models';
import * as types from '../restoreCatalogItems/Models';

export const restoreCatalogItemsProduct = async (
  params: Arguments,
): Promise<types.Response> => {
  const response = await apiClient.get(
    `${ENDPOINTS.RESTORE_CATALOG_ITEMS_PRODUCT}`,
    {
      params,
    },
  );

  return response.data;
};
