import { Arguments, Response } from 'api/catalogs/fetchCatalogProducts/Models';
import { fetchCatalogProducts } from 'api/catalogs/fetchCatalogProducts';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchProducts = createAsyncThunk<
  Response,
  { catalogId: string } & { args: Arguments },
  AsyncThunkOptions
>('catalogProducts/fetchProducts', async (params, { rejectWithValue }) => {
  try {
    return await fetchCatalogProducts(params.catalogId, params.args);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});

export const saveFilterStatusCatalogProducts = createAction<boolean>(
  'catalogProducts/saveFilterStatusCatalogProducts',
);

export const setInitialCatalog = createAction(
  'catalogProducts/setInitialCatalog',
);
