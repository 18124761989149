import { SuccessCardsResponse } from 'api/orders/getOrderSuccessCards/Models';
import { getOrderSuccessCards } from 'api/orders/getOrderSuccessCards';
import { createAsyncThunk } from '@reduxjs/toolkit';

type Params = {
  pageSize: number;
  pageIndex: number;
  isDownload?: boolean;
};

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchOrderInfoCardsById = createAsyncThunk<
  SuccessCardsResponse,
  { id: string | number } & { params: Params } & { isAdmin: boolean } & {
    isFinanceManager?: boolean;
  } & { isCustomerSupport?: boolean } & { isSales?: boolean } & {
    isTech?: boolean;
  },
  AsyncThunkOptions
>('orders/fetchOrderInfoCardsById', async (orderData, { rejectWithValue }) => {
  try {
    return await getOrderSuccessCards(
      orderData.id,
      orderData.params,
      orderData.isAdmin,
      orderData.isFinanceManager,
      orderData.isCustomerSupport,
      orderData.isSales,
      orderData.isTech,
    );
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});
