import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getBrandCatalogsApi } from 'api/products/getBrandCatalogs';

import { Arguments, Response } from 'api/products/brandCatalog';
import { BrandCatalogsFilterInputs } from 'entries/catalogs/filters/brandCatalogsFilter';
import { TableBrandCatalogs } from './Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const setBrandCatalogs = createAction<TableBrandCatalogs[]>(
  'products/setBrandCatalogs',
);

export const fetchBrandCatalogs = createAsyncThunk<
  Response,
  { brandId: number } & { args: Arguments },
  AsyncThunkOptions
>('products/fetchBrandCatalogs', async (params, { rejectWithValue }) => {
  try {
    return await getBrandCatalogsApi(params.brandId, params.args);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});

export const setBrandCatalogsSearch = createAction<BrandCatalogsFilterInputs>(
  'products/setBrandCatalogsSearch',
);
