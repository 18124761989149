import { createReducer } from '@reduxjs/toolkit';
import { CatalogBrandsState } from './Models';
import { fetchEditableBrands } from 'store/bambooCatalog/editableCatalogBrands/actions';
import { clearCatalogsErrors } from 'store/catalogs/actions';

export const initialState: CatalogBrandsState = {
  count: 0,
  items: [],
  pageIndex: 0,
  pageSize: 0,
  errorState: {
    status: null,
    message: '',
  },
  isLoading: true,
  error: null,
};

export const editableCatalogBrands = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchEditableBrands.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchEditableBrands.fulfilled, (state, action) => {
      state.isLoading = false;
      state.count = action.payload.count;
      state.pageSize = action.payload.pageSize;
      state.pageIndex = action.payload.pageIndex;
      state.items = action.payload.items.map((product) => ({
        ...product,
        isValid: true,
        tableData: {
          checked: false,
        },
      }));
    })
    .addCase(fetchEditableBrands.rejected, (state, action) => {
      state.error = action.payload || 500;
      state.isLoading = false;
    })
    .addCase(clearCatalogsErrors, (state) => {
      state.error = null;
    });
});
