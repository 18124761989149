import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { FilterInputs } from 'entries/inventoryCards';
import { getInventoryCards } from 'api/inventoryCards/getInventoryCards';
import {
  InventoryCardsModel,
  Arguments,
} from 'api/inventoryCards/getInventoryCards/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const setInventoryCardsSearch = createAction<FilterInputs>(
  'inventoryCards/findInventoryCard',
);

export const fetchInventoryCards = createAsyncThunk<
  InventoryCardsModel,
  Arguments,
  AsyncThunkOptions
>(
  'inventoryCards/fetchInventoryCards',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getInventoryCards(queryParams);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const saveInventoryCardsFilterStatus = createAction<boolean>(
  'inventoryCards/saveInventoryCardsFilterStatus',
);
