import { ENDPOINTS } from 'api/endpoints';
import { apiClient } from 'config/auth';
import { SuccessCardsResponse } from './Models';

type Params = {
  pageSize: number;
  pageIndex: number;
  isDownload?: boolean;
  status?: string;
};

export const getOrderSuccessCards = async (
  orderId: string | number,
  params: Params,
  isAdmin: boolean,
  isFinanceManager?: boolean,
  isCustomerSupport?: boolean,
  isSales?: boolean,
  isTech?: boolean,
): Promise<SuccessCardsResponse> => {
  const orderCardsWithCodes = `${ENDPOINTS.ORDERS}/${orderId}/cards-with-codes`;
  const orderCardsWithoutCodes = `${ENDPOINTS.ORDERS}/${orderId}/cards`;
  const orderCardsWithCodesClient = `${ENDPOINTS.ORDERS}/${orderId}/client/cards-with-codes`;
  const orderCardsWithoutCodesClient = `${ENDPOINTS.ORDERS}/${orderId}/client/cards`;

  const response = await apiClient.get(
    (isAdmin && !isTech) || isCustomerSupport
      ? orderCardsWithCodes
      : isFinanceManager || isSales || isTech
      ? params.isDownload
        ? orderCardsWithCodes
        : orderCardsWithoutCodes
      : params.isDownload
      ? orderCardsWithCodesClient
      : orderCardsWithoutCodesClient,
    {
      params,
    },
  );

  return response.data;
};
