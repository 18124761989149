import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, OrderHistoryClientModel } from './Models';

export const getOrderHistoryClient = async (
  params: Arguments,
): Promise<OrderHistoryClientModel> => {
  const response = await apiClient.get(`${ENDPOINTS.ORDERS_CLIENT}/history`, {
    params,
  });

  return response.data;
};
