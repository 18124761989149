import {
  AnalyticProductsModel,
  Arguments,
} from 'api/analytics/getProducts/Models';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAnalyticsProducts } from '../../api/analytics/getProducts';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchAnalyticsProducts = createAsyncThunk<
  AnalyticProductsModel,
  Arguments,
  AsyncThunkOptions
>(
  'analytics/fetchAnalyticsProducts',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getAnalyticsProducts(queryParams);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
