import { createReducer } from '@reduxjs/toolkit';

import { fetchEnebaAccountList } from './actions';
import { State } from './Models';

export const initialState: State = {
  enebaAccountList: [],
  error: null,
  isLoading: false,
};

export const enebaFetchAccountList = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchEnebaAccountList.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchEnebaAccountList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.enebaAccountList = action.payload;
    })
    .addCase(fetchEnebaAccountList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    });
});
