import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Supplier } from 'api/suppliers/Models';

export const getSupplierById = async (
  id: string | number,
): Promise<Supplier> => {
  const response = await apiClient.get(`${ENDPOINTS.SUPPLIERS}/${id}`);

  return response.data;
};
