import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getProductCatalogsApi } from 'api/products/getProductCatalogs';

import { Arguments, Response } from 'api/products/ProductCatalog';
import { ProductCatalogsFilterInputs } from 'entries/catalogs/filters/productCatalogsFilter';
import { TableProductCatalogs } from './Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const setIntegratedProductCatalogs = createAction<
  TableProductCatalogs[]
>('products/setIntegratedProductCatalogs');

export const fetchIntegratedProductCatalogs = createAsyncThunk<
  Response,
  { productId: number } & { args: Arguments },
  AsyncThunkOptions
>(
  'products/fetchIntegratedProductCatalogs',
  async (params, { rejectWithValue }) => {
    try {
      return await getProductCatalogsApi(params.productId, params.args);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const setIntegratedProductCatalogsSearch = createAction<
  ProductCatalogsFilterInputs
>('products/setIntegratedProductCatalogsSearch');
