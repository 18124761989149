import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, EnebaCategory } from './Models';
import { omit } from 'lodash';

export const getEnebaSelectCategoryTable = async (
  params: Arguments,
): Promise<EnebaCategory> => {
  const response = await apiClient.get(
    `${ENDPOINTS.ENEBA}/${params.enebaId}${ENDPOINTS.UNMAPPED_PRODUCT}`,
    {
      params: omit(params, 'enebaId'),
    },
  );

  return response.data;
};
