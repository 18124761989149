import { createReducer } from '@reduxjs/toolkit';
import { OrdersFilterField } from 'entries/orders';
import {
  fetchOrdersHistory,
  saveOrdersHistoryFilterStatus,
  setOrderHistorySearch,
} from 'store/ordersHistory/actions';

import { OrdersHistoryState } from './Models';

export const initialState: OrdersHistoryState = {
         filter: {
           [OrdersFilterField.DateFrom]: '',
           [OrdersFilterField.DateTo]: '',
           [OrdersFilterField.Search]: '',
           [OrdersFilterField.Client]: '',
           [OrdersFilterField.Status]: '',
           [OrdersFilterField.Brand]: '',
           [OrdersFilterField.Supplier]: '',
           [OrdersFilterField.Currency]: '',
           [OrdersFilterField.OrderType]: '',
           [OrdersFilterField.Denomination]: '',
           [OrdersFilterField.Region]: '',
           [OrdersFilterField.DownloadStatus]: '',
         },
         ordersHistory: {
           pageSize: 0,
           pageIndex: 0,
           count: 0,
           items: [],
         },
         isLoading: false,
         isFilterActive: false,
         error: null,
       };

export const ordersHistory = createReducer(initialState, (builder) => {
  builder
    .addCase(setOrderHistorySearch, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(fetchOrdersHistory.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchOrdersHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ordersHistory = action.payload;
    })
    .addCase(fetchOrdersHistory.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    })
    .addCase(saveOrdersHistoryFilterStatus, (state, action) => {
      state.isFilterActive = action.payload;
    });
});
