import { createReducer } from '@reduxjs/toolkit';

import { SupplierMappingTableState } from './Models';
import { fetchAvailableBrands, fetchBrandSupplierMapping } from './actions';

export const initialState: SupplierMappingTableState = {
  supplierMapping: {
    mapping: {
      pageSize: 0,
      pageIndex: 0,
      count: 0,
      items: [],
    },
    isLoading: true,
    error: null,
  },
  availableBrands: {
    brands: {
      pageSize: 0,
      pageIndex: 0,
      count: 0,
      items: [],
    },
    isLoading: true,
    error: null,
  },
};

export const brandSupplierMapping = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchBrandSupplierMapping.pending, (state) => {
      state.supplierMapping.isLoading = true;
      state.supplierMapping.error = null;
    })
    .addCase(fetchBrandSupplierMapping.fulfilled, (state, action) => {
      state.supplierMapping.isLoading = false;
      state.supplierMapping.mapping = action.payload;
    })
    .addCase(fetchBrandSupplierMapping.rejected, (state, action) => {
      state.supplierMapping.isLoading = false;
      state.supplierMapping.error = action.payload || 500;
    })
    .addCase(fetchAvailableBrands.pending, (state) => {
      state.availableBrands.isLoading = true;
      state.availableBrands.error = null;
    })
    .addCase(fetchAvailableBrands.fulfilled, (state, action) => {
      state.availableBrands.isLoading = false;
      state.availableBrands.brands = action.payload;
    })
    .addCase(fetchAvailableBrands.rejected, (state, action) => {
      state.availableBrands.isLoading = false;
      state.availableBrands.error = action.payload || 500;
    });
});
