import { createReducer } from '@reduxjs/toolkit';

import { EnebaSelectTableState } from './Models';
import { fetchEnebaProductSelectTable } from './actions';

export const initialState: EnebaSelectTableState = {
  enebaSelectProduct: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: true,
  isFilterActive: false,
  error: null,
};

export const enebaSelectTableProduct = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(fetchEnebaProductSelectTable.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchEnebaProductSelectTable.fulfilled, (state, action) => {
        state.isLoading = false;
        state.enebaSelectProduct = action.payload;
      })
      .addCase(fetchEnebaProductSelectTable.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 500;
      });
  },
);
