import { createReducer } from '@reduxjs/toolkit';

import { ReconciliationState } from './Models';
import { fetchReconciliationProcess } from './action';

export const initialState: ReconciliationState = {
  reconciliationProcess: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: true,
  error: null,
};

export const reconciliationProcess = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchReconciliationProcess.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchReconciliationProcess.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reconciliationProcess = action.payload;
    })
    .addCase(fetchReconciliationProcess.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    });
});
