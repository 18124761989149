import { createAsyncThunk } from '@reduxjs/toolkit';
import { getReconciliationProcessHistory } from 'api/reconciliationProcess/getReconciliationProcessHistory';
import {
  ReconciliationProcessModel,
  Arguments,
} from 'api/reconciliationProcess/getReconciliationProcessHistory/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchReconciliationProcess = createAsyncThunk<
  ReconciliationProcessModel,
  Arguments,
  AsyncThunkOptions
>(
  'reconciliation/fetchReconciliationProcessHistory',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getReconciliationProcessHistory(queryParams);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
