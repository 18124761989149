import { createAsyncThunk } from '@reduxjs/toolkit';
import { restoreCatalogItemsProduct as restoreCatalogItemsProductApi } from 'api/catalogs/restoreCatalogItemsProduct';
import { restoreCatalogItems as restoreCatalogItemsApi } from 'api/catalogs/restoreCatalogItems';
import { Arguments, Response } from 'api/catalogs/restoreCatalogItems/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const restoreCatalogItems = createAsyncThunk<
  Response,
  Arguments,
  AsyncThunkOptions
>('catalogItems/restoreCatalogItems', async (params, { rejectWithValue }) => {
  try {
    return await restoreCatalogItemsApi(params);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});

export const restoreCatalogItemsProduct = createAsyncThunk<
  Response,
  Arguments,
  AsyncThunkOptions
>(
  'catalogItems/restoreCatalogItemsProduct',
  async (params, { rejectWithValue }) => {
    try {
      return await restoreCatalogItemsProductApi(params);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
