export enum RefundOrderField {
  AvailableRefundAmount = 'availableRefundAmount',
  RefundAmount = 'refundAmount',
  RefundReason = 'refundReason',
}

export interface RefundOrderInputs {
  [RefundOrderField.AvailableRefundAmount]: number;
  [RefundOrderField.RefundAmount]: number;
  [RefundOrderField.RefundReason]: string;
}
