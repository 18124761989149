import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, Response } from './Models';

export const getInventoryInvoices = async (
  params: Arguments,
): Promise<Response> => {
  const response = await apiClient.get(ENDPOINTS.SUPPLIER_INVOICE, { params });
  return response.data;
};
