import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, EnebaProductsMainTableModel } from './Models';
import { omit } from 'lodash';

export const getProductEnebaMainTable = async (
  params: Arguments,
): Promise<EnebaProductsMainTableModel> => {
  const response = await apiClient.get(
    `${ENDPOINTS.ENEBA}/${params.enebaId}${ENDPOINTS.PRODUCTS}`,
    { params: omit(params, 'enebaId') },
  );

  return response.data;
};
