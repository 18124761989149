import { parseJwt } from 'utils/parseJwt';
import { ROLES } from 'config/RBAC/userRoles';
import { TokenRepository } from './tokenRepositoryInterface';

const accessTokenKey = 'accessToken';
const identityToken = 'identityToken';
const refreshTokenKey = 'refreshToken';

export const createTokenRepository = (): TokenRepository => {
  return {
    getAccessToken() {
      return localStorage.getItem(accessTokenKey);
    },

    setAccessToken(token: string) {
      localStorage.setItem(accessTokenKey, token);
    },

    removeToken() {
      localStorage.removeItem(accessTokenKey);
    },

    getIdentityToken() {
      return localStorage.getItem(identityToken);
    },

    setIdentityToken(idToken: string) {
      return localStorage.setItem(identityToken, idToken);
    },

    removeIdentityToken() {
      return localStorage.removeItem(identityToken);
    },

    getRefreshToken() {
      return localStorage.getItem(refreshTokenKey);
    },

    setRefreshToken(refreshToken: string) {
      localStorage.setItem(refreshTokenKey, refreshToken);
    },

    removeRefreshToken() {
      localStorage.removeItem(refreshTokenKey);
    },

    getUserRole() {
      const token = localStorage.getItem(accessTokenKey);

      if (!token) {
        return ROLES.USER;
      }

      const parsedToken = parseJwt(token);

      const userRole = () => {
        if (parsedToken['cognito:groups'].includes(ROLES.ADMIN)) {
          return ROLES.ADMIN;
        } else if (
          parsedToken['cognito:groups'].includes(ROLES.FINANCE_MANAGER)
        ) {
          return ROLES.FINANCE_MANAGER;
        } else if (
          parsedToken['cognito:groups'].includes(ROLES.CUSTOMER_SUPPORT)
        ) {
          return ROLES.CUSTOMER_SUPPORT;
        } else if (parsedToken['cognito:groups'].includes(ROLES.SALES)) {
          return ROLES.SALES;
        } else if (
          parsedToken['cognito:groups'].includes(ROLES.OPERATIONS_EXECUTIVE)
        ) {
          return ROLES.OPERATIONS_EXECUTIVE;
        } else if (
          parsedToken['cognito:groups'].includes(ROLES.ACCOUNT_MANAGER)
        ) {
          return ROLES.ACCOUNT_MANAGER;
        } else if (
          parsedToken['cognito:groups'].includes(ROLES.MARKETPLACE_MANAGER)
        ) {
          return ROLES.MARKETPLACE_MANAGER;
        } else if (parsedToken['cognito:groups'].includes(ROLES.TECH)) {
          return ROLES.TECH;
        } else {
          return ROLES.USER;
        }
      };

      return userRole();
    },
  };
};
