import { createAsyncThunk } from '@reduxjs/toolkit';
import { formatStringsArrayIntoSelectOptions } from 'utils/formatStringsArrayIntoSelectOptions';
import {
  getAccountType,
  getFilterBrands,
  getDiscountTypes,
  getFilterClients,
  getFilterStatuses,
  getFilterSuppliers,
  getFilterCountries,
  getFilterCurrencies,
  getIntegrationTypes,
  getFilterPaymentMethods,
  getCommercialStructures,
  getUserRoles,
} from 'api/filters';
import { getClientBrands } from 'api/catalogs/getClientBrands';
import { getXeroAccounts } from 'api/xero/getAccounts';
import { Options } from 'entries/filters';
import { userRole } from '../../config/RBAC/RBAC';
import { routes } from '../../routes/routes';
import { getFilterNonIntegratedSuppliers } from 'api/filters/getFilterNonIntegratedSupplier';
import { getFilterAccountCurrencies } from 'api/filters/getFilterAccountCurrencies';
import { getXeroContacts } from 'api/xero/getContacts';
import { getXeroThemes } from 'api/xero/getThemes';
import { getFilterCategories } from 'api/filters/getFilterCategories';
import { Params } from 'api/filters/getFilterBrands/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const updateBrandsFilter = createAsyncThunk<
  Options,
  Params,
  AsyncThunkOptions
>(
  'filters/updateBrandsFilter',
  async (queryParams: Partial<Params>, { rejectWithValue }) => {
    try {
      return await getFilterBrands(queryParams);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
export const updateClientsFilter = createAsyncThunk(
  'filters/updateClientsFilter',
  async (_, { rejectWithValue }) => {
    try {
      return await getFilterClients();
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
export const updateNonIntegratedBrandsFilter = createAsyncThunk(
  'filters/updateNonIntegratedBrandsFilter',
  async (_, { rejectWithValue }) => {
    try {
      return await getFilterBrands({ isIntegrated: false });
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const updateSuppliersFilter = createAsyncThunk(
  'filters/updateSuppliersFilter',
  async (_, { rejectWithValue }) => {
    try {
      return await getFilterSuppliers();
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
export const updateNonIntegratedSuppliersFilter = createAsyncThunk(
  'filters/updateNonIntegratedSuppliersFilter',
  async (_, { rejectWithValue }) => {
    try {
      return await getFilterNonIntegratedSuppliers();
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const setFilters = createAsyncThunk(
  'filters/setFilters',
  async (userRoleId: number) => {
    const brand = {};
    const countries = {};
    const currencies = {};
    const accountCurrencies = {};
    const statuses = {};
    const integratedBrands = {};
    const nonIntegratedBrands = {};
    const clients = {};
    const suppliers = {};
    const integratedSuppliers = {};
    const nonIntegratedSuppliers = {};
    const accountType = {};
    const discountTypes = {};
    const paymentMethods = {};
    const integrationType = {};
    const commercialStructures = {};
    const userRoles = {};
    const xeroAccounts = {};
    const xeroContacts = {};
    const xeroThemes = {};
    const categories = {};

    const filters = {
      brand,
      countries,
      currencies,
      accountCurrencies,
      statuses,
      integratedBrands,
      nonIntegratedBrands,
      clients,
      suppliers,
      integratedSuppliers,
      nonIntegratedSuppliers,
      accountType,
      discountTypes,
      paymentMethods,
      integrationType,
      commercialStructures,
      userRoles,
      xeroAccounts,
      xeroContacts,
      xeroThemes,
      categories,
    };

    const result = await Promise.allSettled(
      userRoleId !== userRole.User
        ? [
            getFilterBrands(),
            getFilterCountries(),
            getFilterCurrencies(),
            getFilterAccountCurrencies(),
            getFilterStatuses(),
            getFilterBrands({ isIntegrated: true }),
            getFilterBrands({ isIntegrated: false }),
            getFilterClients(),
            getFilterSuppliers(),
            getFilterSuppliers({ isIntegrated: true }),
            getFilterNonIntegratedSuppliers(),
            getAccountType(),
            getDiscountTypes(),
            getFilterPaymentMethods(),
            getIntegrationTypes(),
            getCommercialStructures(),
            getUserRoles(),
            getXeroAccounts(),
            getXeroContacts(),
            getXeroThemes(),
            getFilterCategories(),
          ]
        : [
            getClientBrands(),
            getFilterCountries(),
            getFilterCurrencies(),
            getFilterAccountCurrencies(),
          ],
    );
    const rejectedFilters = result.filter((item) => item.status === 'rejected');

    // const maintenance = result
    //   ?.map((el) => (el as any).reason.message === 'Network Error')
    //   .includes(false);
    // if (!maintenance) {
    //   window.location.assign(routes.maintenance);
    // }
    if (
      rejectedFilters.length === Object.keys(filters).length &&
      'reason' in rejectedFilters[0]
    ) {
      return;
    }
    if (
      rejectedFilters.length > 0 &&
      rejectedFilters.some(
        (el) => (el as any).reason.message === 'Network Error',
      )
    ) {
      const maintenance = result
        ?.map((el) => (el as any).reason.message === 'Network Error')
        .includes(false);
      if (!maintenance) {
        window.location.assign(routes.maintenance);
      } else return;
    }

    const resultFilter = result.map((item) =>
      item.status === 'fulfilled' ? item.value : [],
    );

    (Object.keys(filters) as Array<keyof typeof filters>).forEach((key, i) => {
      filters[key] = resultFilter[i];
    });

    return {
      brands: filters.brand as Options,
      statuses: formatStringsArrayIntoSelectOptions(
        filters.statuses as string[],
      ),
      integratedBrands: filters.integratedBrands as Options,
      nonIntegratedBrands: filters.nonIntegratedBrands as Options,
      countries: filters.countries as Options,
      currencies: formatStringsArrayIntoSelectOptions(
        filters.currencies as string[],
      ),
      accountCurrencies: formatStringsArrayIntoSelectOptions(
        filters.accountCurrencies as string[],
      ),
      clients: filters.clients as Options,
      suppliers: filters.suppliers as Options,
      integratedSuppliers: filters.integratedSuppliers as Options,
      nonIntegratedSuppliers: filters.nonIntegratedSuppliers as Options,
      accountType: formatStringsArrayIntoSelectOptions(
        filters.accountType as string[],
      ),
      discountTypes: formatStringsArrayIntoSelectOptions(
        filters.discountTypes as string[],
      ),
      paymentMethods: formatStringsArrayIntoSelectOptions(
        filters.paymentMethods as string[],
      ),
      integrationType: formatStringsArrayIntoSelectOptions(
        filters.integrationType as string[],
      ),
      commercialStructures: formatStringsArrayIntoSelectOptions(
        filters.commercialStructures as string[],
      ),
      userRoles: filters.userRoles as Options,
      xeroAccounts: filters.xeroAccounts as Options,
      xeroContacts: filters.xeroContacts as Options,
      xeroThemes: filters.xeroThemes as Options,
      categories: filters.categories as Options,
    };
  },
);
