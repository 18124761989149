import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { Arguments, Response } from 'api/products/Models';
import { getProductsApi } from 'api/products/getProducts';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchEditableIntegratedProducts = createAsyncThunk<
  Response,
  Arguments,
  AsyncThunkOptions
>(
  'products/fetchEditableIntegratedProducts',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getProductsApi({ ...queryParams, isIntegrated: true });
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
