import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCatalogBrandsWithFilter } from 'api/bambooCatalogs/fetchCatalogBrandsWithFilter';
import {
  Arguments,
  CatalogBrandsResponse,
} from 'api/bambooCatalogs/fetchCatalogBrandsWithFilter/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchInactiveBrands = createAsyncThunk<
  CatalogBrandsResponse,
  { catalogId: string } & { args: Arguments },
  AsyncThunkOptions
>('catalogBrands/fetchInactiveBrands', async (params, { rejectWithValue }) => {
  try {
    return await fetchCatalogBrandsWithFilter(params.catalogId, params.args);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});

export const saveInactiveFilterStatus = createAction<boolean>(
  'catalogBrands/saveInactiveFilterStatus',
);

export const setInitialInactiveCatalog = createAction(
  'catalogBrands/setInitialInactiveCatalog',
);
