import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';

export const getReservationStatus = async (
  accountId: string,
): Promise<boolean> => {
  const response = await apiClient.get(
    `${ENDPOINTS.ENEBA}/${accountId}/reservations `,
  );

  return response.data;
};
