import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { OrderByStatusResponse } from './Models';

export const getOrderByStatus = async (
         orderId: string | number,
         isAdmin: boolean,
         isFinanceManager?: boolean,
         isCustomerSupport?: boolean,
         isSales?: boolean,
       ): Promise<OrderByStatusResponse> => {
         const orderCardsAdmin = `${ENDPOINTS.ORDERS}/${orderId}/cards/statuses`;
         const orderCardsClient = `${ENDPOINTS.ORDERS}/${orderId}/cards/statuses`;

         const response = await apiClient.get(
           isAdmin || isFinanceManager || isCustomerSupport || isSales
             ? orderCardsAdmin
             : orderCardsClient,
         );

         return response.data;
       };
