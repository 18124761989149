import { createReducer } from '@reduxjs/toolkit';

import { GamivoCategoryState } from './Models';
import { fetchGamivoCategoryTable } from './actions';

export const initialState: GamivoCategoryState = {
  gamivoCategory: [],
  isLoading: true,
  isFilterActive: false,
  error: null,
};

export const gamivoSelectCategoryTable = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(fetchGamivoCategoryTable.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchGamivoCategoryTable.fulfilled, (state, action) => {
        state.isLoading = false;
        state.gamivoCategory = action.payload;
      })
      .addCase(fetchGamivoCategoryTable.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 500;
      });
  },
);
