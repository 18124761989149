import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, ReconciliationModel } from './Models';

export const getReconciliationAccount = async (
  params: Arguments,
): Promise<ReconciliationModel> => {
  const response = await apiClient.get(
    `${ENDPOINTS.TRANSACTION_REPORT}/reconciliation`,
    { params },
  );

  return response.data;
};
