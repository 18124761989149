import { createAsyncThunk } from '@reduxjs/toolkit';
import { Arguments } from 'api/catalogs/fetchCatalogClients/Models';
import { fetchCatalogClients } from 'api/catalogs/fetchCatalogClients';
import { Response } from 'api/catalogs/fetchCatalogClients/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchEditableClients = createAsyncThunk<
  Response,
  { catalogId: string } & { args: Arguments },
  AsyncThunkOptions
>(
  'editableCatalogClients/fetchEditableClients',
  async (params, { rejectWithValue }) => {
    try {
      return await fetchCatalogClients(params.catalogId, params.args);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
