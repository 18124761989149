import axios, { AxiosPromise } from 'axios';
import { generatePath } from 'react-router-dom';
import { TokenRepository } from './tokenRepository/tokenRepositoryInterface';
import { routes } from 'routes/routes';
import {
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  localStoragePreviousPagePath,
} from '../constants';

export const createClient = ({
  tokenRepository,
}: {
  tokenRepository: TokenRepository;
}) => {
  const client = axios.create({
    baseURL: process.env.REACT_APP_SERVER_PATH,
    timeout: 600000,
    timeoutErrorMessage: 'Request timeout',
  });

  let refreshRequest: AxiosPromise | null = null;

  client.interceptors.request.use((config) => {
    const identityToken = tokenRepository.getIdentityToken();

    if (!identityToken) {
      return config;
    }

    const newConfig = {
      headers: {},
      withCredentials: true,
      ...config,
    };

    newConfig.headers.Authorization = `bearer ${identityToken}`;

    return newConfig;
  });

  // If token is expired, try to refresh it
  client.interceptors.response.use(
    (value) => value,
    async (error) => {
      if (!error.response || !error.config) {
        throw error;
      }

      if (error.response.status !== RESPONSE_STATUS_401) {
        throw error;
      }
      if (
        (error.response.status === RESPONSE_STATUS_401 ||
          error.response.status === RESPONSE_STATUS_403) &&
        refreshRequest
      ) {
        tokenRepository.removeRefreshToken();
        tokenRepository.removeIdentityToken();
        tokenRepository.removeToken();

        localStorage.setItem(
          localStoragePreviousPagePath,
          window.location.pathname,
        );
        window.location.assign(generatePath(routes.signInLanding));
      }

      if (!refreshRequest) {
        refreshRequest = axios.post(
          `${process.env.REACT_APP_SERVER_PATH}/auth/refresh-token`,
          {
            refreshToken: tokenRepository.getRefreshToken(),
          },
        );
      }

      const response = await refreshRequest;

      tokenRepository.setAccessToken(response.data.accessToken);
      tokenRepository.setIdentityToken(response.data.identityToken);

      refreshRequest = null;

      const newRequest = {
        ...error.config,
        headers: {
          Authorization: `Bearer ${response.data.identityToken}`,
        },
        retry: true,
      };

      return client(newRequest);
    },
  );

  return client;
};
