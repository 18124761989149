export enum SuppliersFilterField {
  Search = 'searchText',
  IntegrationType = 'isIntegrated',
  Currency = 'currencyCode',
}

export interface FilterInputs {
  [SuppliersFilterField.Search]: string;
  [SuppliersFilterField.IntegrationType]: string;
  [SuppliersFilterField.Currency]: string;
}
