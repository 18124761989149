import { apiClient } from 'config/auth';
import { Arguments, CatalogBrandsResponse } from './Models';

export const fetchCatalogBrandsWithFilter = async (
  catalogId: string,
  params: Arguments,
): Promise<CatalogBrandsResponse> => {
  const response = await apiClient.get(`/catalogs-brands/${catalogId}/brands`, {
    params,
  });

  return response.data;
};
