import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, G2aSelectProduct } from './Models';

export const getProductG2aSelectTable = async (
  params: Arguments,
): Promise<G2aSelectProduct> => {
  const response = await apiClient.get(
    ENDPOINTS.G2A + ENDPOINTS.AVAILABLE_PRODUCTS,
    {
      params,
    },
  );

  return response.data;
};
