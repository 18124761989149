import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, GamivoCategory } from './Models';
import { GamivoCategoryTable } from 'entries/gamivo';

export const getGamivoCategory = async (
  params: Arguments,
): Promise<GamivoCategoryTable[]> => {
  const response = await apiClient.get(
    `${ENDPOINTS.GAMIVO}${ENDPOINTS.UNMAPPED_PRODUCT}`,
    {
      params,
    },
  );

  return response.data;
};
