export enum InventoryReportsFilterField {
  SupplierName = 'supplierName',
  InvoiceNumber = 'invoiceNumber',
  CodeFileReference = 'codeFileReference',
  CreatedDateFrom = 'createdDateFrom',
  CreatedDateTo = 'createdDateTo',
}

export interface FilterInputs {
  [InventoryReportsFilterField.SupplierName]: string;
  [InventoryReportsFilterField.InvoiceNumber]: string;
  [InventoryReportsFilterField.CodeFileReference]: string;
  [InventoryReportsFilterField.CreatedDateFrom]: string;
  [InventoryReportsFilterField.CreatedDateTo]: string;
}

export enum InventoryReportCardFilterField {
  InventoryReportId = 'inventoryReportId',
  Status = 'status',
}

export interface InventoryReportCardFilterInputs {
  [InventoryReportCardFilterField.InventoryReportId]?: number;
  [InventoryReportCardFilterField.Status]: string;
}
