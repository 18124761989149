import { InventoryState } from 'store/inventory/Models';
import { createReducer } from '@reduxjs/toolkit';
import {
  fetchAllProductInventory,
  saveFilterStatusAllProductInventory,
} from 'store/inventory/allProductInventory/actions';

export const initialState: InventoryState = {
  inventory: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: false,
  error: null,
  isFilterActive: false,
};

export const allProductInventory = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAllProductInventory.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchAllProductInventory.fulfilled, (state, action) => {
      state.inventory = action.payload;
      state.isLoading = false;
    })
    .addCase(fetchAllProductInventory.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    })
    .addCase(saveFilterStatusAllProductInventory, (state, action) => {
      state.isFilterActive = action.payload;
    });
});
