import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GamivoCategory,
  Arguments,
} from '../../../api/gamivo/getGamivoCategory/Models';
import { getGamivoCategory } from 'api/gamivo/getGamivoCategory';
import { GamivoCategoryTable } from 'entries/gamivo';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchGamivoCategoryTable = createAsyncThunk<
  GamivoCategoryTable[],
  Arguments,
  AsyncThunkOptions
>(
  'eneba/fetchGamivoCategoryTable',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getGamivoCategory(queryParams);
    } catch (error) {
      if (error.response && error.response.status >= 500) {
        console.error({
          message: error.response.data.message,
          status: error.response.status,
          statusText: error.response.statusText,
        });
      }
      return rejectWithValue(error.response.status);
    }
  },
);
