import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  G2aSelectProduct,
  Arguments,
} from '../../../api/g2a/getAvailableProduct/Models';
import { getProductG2aSelectTable } from 'api/g2a/getAvailableProduct';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchG2aProductSelectTable = createAsyncThunk<
  G2aSelectProduct,
  Arguments,
  AsyncThunkOptions
>('g2a/fetchSelectProductTable', async (queryParams, { rejectWithValue }) => {
  try {
    return await getProductG2aSelectTable(queryParams);
  } catch (error) {
    console.error(error.response.data);
    return rejectWithValue(error.response.status);
  }
});
