import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { Arguments, Response } from 'api/products/Models';
import { getProductsApi } from 'api/products/getProducts';
import { FilterInputs } from 'entries/products';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const setNonIntegratedProductsSearch = createAction<FilterInputs>(
  'productss/findNonIntegratedProducts',
);

export const fetchNonIntegratedProducts = createAsyncThunk<
  Response,
  Arguments,
  AsyncThunkOptions
>(
  'products/fetchNonIntegratedProducts',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getProductsApi({ ...queryParams, isIntegrated: false });
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const saveNonIntegratedProductsFilterStatus = createAction<boolean>(
  'catalogProducts/saveIntegratedProductsFilterStatus',
);
