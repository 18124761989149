import { createReducer } from '@reduxjs/toolkit';
import { InventoryCardsFilterField } from 'entries/inventoryCards';
import { InventoryCardsState } from './Models';
import {
  fetchInventoryCards,
  saveInventoryCardsFilterStatus,
  setInventoryCardsSearch,
} from './actions';

export const initialState: InventoryCardsState = {
         filter: {
           [InventoryCardsFilterField.DateFrom]: '',
           [InventoryCardsFilterField.DateTo]: '',
           [InventoryCardsFilterField.Brand]: '',
           [InventoryCardsFilterField.Product]: '',
           [InventoryCardsFilterField.FilterBy]: '',
           [InventoryCardsFilterField.FilterValue]: '',
           [InventoryCardsFilterField.Client]: '',
           [InventoryCardsFilterField.Supplier]: '',
           [InventoryCardsFilterField.CardStatus]: '',
           [InventoryCardsFilterField.CardCode]: '',
           [InventoryCardsFilterField.CardUrl]: '',
           [InventoryCardsFilterField.ExpiringDate]: '',
           [InventoryCardsFilterField.Denomination]: '',
           [InventoryCardsFilterField.SupplierInvoiceNumber]: '',
         },
         inventoryCards: {
           pageSize: 0,
           pageIndex: 0,
           count: 0,
           items: [],
         },
         isLoading: true,
         isFilterActive: false,
         error: null,
       };

export const inventoryCards = createReducer(initialState, (builder) => {
  builder
    .addCase(setInventoryCardsSearch, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(fetchInventoryCards.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchInventoryCards.fulfilled, (state, action) => {
      state.isLoading = false;
      state.inventoryCards = action.payload;
    })
    .addCase(fetchInventoryCards.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    })
    .addCase(saveInventoryCardsFilterStatus, (state, action) => {
      state.isFilterActive = action.payload;
    });
});
