import { OrdersState } from './Models';
import { createReducer } from '@reduxjs/toolkit';
import {
  fetchMarketplaceOrderById,
  fetchOrderInfoById,
  resetActiveOrderError,
  updateOrderInformation,
} from 'store/order/orderInfo/actions';
import { fetchOrderInfoCardsById } from 'store/order/orderSuccessCards/actions';
import { fetchAllOrderItemById } from './orderAllItems/action';
import { fetchOrderByStatus } from './OrderByStatus/actions';

export const initialState: OrdersState = {
  order: {
    orderInfo: null,
    isLoading: false,
    error: null,
    successCards: {
      cards: {
        pageSize: 0,
        pageIndex: 0,
        count: 0,
        items: [],
      },
      isLoading: true,
      error: null,
    },
    groupedCards: {
      cards: [],
      isLoading: true,
      error: null,
    },
    allItems: {
      cards: {
        pageSize: 0,
        pageIndex: 0,
        count: 0,
        items: [],
      },
      isLoading: false,
      error: null,
    },
    markertplaceOrder: null,
  },
};

export const order = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchOrderInfoById.pending, (state) => {
      state.order.isLoading = true;
      state.order.error = null;
    })
    .addCase(fetchOrderInfoById.fulfilled, (state, action) => {
      state.order.isLoading = false;
      state.order.orderInfo = action.payload;
    })
    .addCase(fetchOrderInfoById.rejected, (state, action) => {
      state.order.isLoading = false;
      state.order.error = action.payload || 500;
    })
    .addCase(updateOrderInformation, (state, action) => {
      state.order.isLoading = false;
      state.order.orderInfo = action.payload;
    })
    .addCase(fetchOrderInfoCardsById.pending, (state) => {
      state.order.successCards.isLoading = true;
      state.order.successCards.error = null;
    })
    .addCase(fetchOrderInfoCardsById.fulfilled, (state, action) => {
      state.order.successCards.isLoading = false;
      state.order.successCards.cards = action.payload;
    })
    .addCase(fetchOrderInfoCardsById.rejected, (state, action) => {
      state.order.successCards.isLoading = false;
      state.order.successCards.error = action.payload || 500;
    })
    .addCase(fetchAllOrderItemById.pending, (state, action) => {
      state.order.allItems.isLoading = true;
      state.order.allItems.error = null;
    })
    .addCase(fetchAllOrderItemById.fulfilled, (state, action) => {
      state.order.allItems.isLoading = false;
      state.order.allItems.cards = action.payload;
    })
    .addCase(fetchAllOrderItemById.rejected, (state, action) => {
      state.order.allItems.isLoading = false;
      state.order.allItems.error = action.payload || 500;
    })
    .addCase(fetchOrderByStatus.pending, (state) => {
      state.order.groupedCards.isLoading = true;
      state.order.groupedCards.error = null;
    })
    .addCase(fetchOrderByStatus.fulfilled, (state, action) => {
      state.order.groupedCards.isLoading = false;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.order.groupedCards.cards = action.payload;
    })
    .addCase(fetchOrderByStatus.rejected, (state, action) => {
      state.order.groupedCards.isLoading = false;
      state.order.groupedCards.error = action.payload || 500;
    })
    .addCase(resetActiveOrderError, (state) => {
      state.order.error = null;
    })
    .addCase(fetchMarketplaceOrderById.fulfilled, (state, action) => {
      state.order.isLoading = false;
      state.order.markertplaceOrder = action.payload;
    });
});
