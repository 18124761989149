import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { InventoryResponse } from './Models';
import { FilterInputs } from 'entries/inventory/filters';

export interface Arguments extends FilterInputs {
  pageSize: number | string;
  pageIndex: number | string;
  cardStatus?: string;
}

export const getProductInventory = async (
  id: string,
  params: Arguments,
): Promise<InventoryResponse> => {
  const response = await apiClient.get(`${ENDPOINTS.INVENTORY}/${id}`, {
    params,
  });

  return response.data;
};
