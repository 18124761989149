import { createReducer } from '@reduxjs/toolkit';
import { fetchAnalyticsReport } from 'store/analytics/actions';

import { AnalyticsReportState } from './Models';

export const initialState: AnalyticsReportState = {
  analyticsReport: {
    items: [],
  },
  isLoading: false,
  error: null,
};

export const analyticsReport = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAnalyticsReport.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchAnalyticsReport.fulfilled, (state, action) => {
      state.isLoading = false;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { data } = action.payload;
      state.analyticsReport = data;
    })
    .addCase(fetchAnalyticsReport.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    });
});
