export enum CatalogBrandsFilterField {
  Search = 'searchText',
  SuppliersId = 'supplierId',
  Country = 'country',
  Currency = 'currency',
  Brand = 'brandId',
  Denomination = 'denomination',
  Sku = 'sku',
  IsIntegrated = 'isIntegrated',
}

export interface CatalogBrandsFilterInputs {
  [CatalogBrandsFilterField.Search]: string;
  [CatalogBrandsFilterField.SuppliersId]: string;
  [CatalogBrandsFilterField.Country]: string;
  [CatalogBrandsFilterField.Currency]: string;
  [CatalogBrandsFilterField.Brand]: string;
  [CatalogBrandsFilterField.Denomination]: string;
  [CatalogBrandsFilterField.Sku]: string;
  [CatalogBrandsFilterField.IsIntegrated]?: boolean;
}
