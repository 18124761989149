import { SuppliersState } from './Models';
import { SuppliersFilterField } from 'entries/suppliers';
import { createReducer } from '@reduxjs/toolkit';
import {
  fetchSupplierById,
  fetchSuppliers,
  fetchSupplierAccounts,
  fetchSupplierProductConfigurations,
  setSuppliersSearch,
  resetActiveSupplierError,
  addSupplierToList,
  updateSupplier,
  addProductConfigurationsToList,
  updateProductConfigurationsList,
  deleteProductConfigurationsFromList,
  changeStatus,
  fetchSupplierLiveCatalogs,
  resetSupplierLiveCatalogError,
} from 'store/suppliers/actions';

export const initialState: SuppliersState = {
  suppliers: {
    pageSize: 10,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  filter: {
    [SuppliersFilterField.Search]: '',
    [SuppliersFilterField.Currency]: '',
    [SuppliersFilterField.IntegrationType]: '',
  },
  isLoading: true,
  errors: {
    suppliersList: null,
    activeSupplier: null,
    supplierAccounts: null,
    supplierProductConfigurations: null,
    supplierCatalogs: null,
  },
  activeSupplier: {
    isLoading: true,
    supplier: null,
  },
  activeSupplierAccounts: {
    isLoading: true,
    supplierAccounts: null,
  },
  activeSupplierProductConfigurations: {
    isLoading: true,
    productConfigurations: {
      pageSize: 10,
      pageIndex: 0,
      count: 0,
      items: [],
    },
  },
  supplierCatalogs: {
    isLoading: true,
    supplierCatalogs: {
      pageSize: 10,
      pageIndex: 0,
      count: 0,
      items: [],
    },
  },
};

export const suppliers = createReducer(initialState, (builder) => {
  builder
    .addCase(setSuppliersSearch, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(resetActiveSupplierError, (state) => {
      state.errors.activeSupplier = null;
    })
    .addCase(addSupplierToList, (state, action) => {
      state.suppliers.items = [action.payload, ...state.suppliers.items];
    })
    .addCase(updateSupplier, (state, action) => {
      state.activeSupplier.supplier = action.payload;
    })
    .addCase(
      addProductConfigurationsToList,
      ({ activeSupplierProductConfigurations }, action) => {
        activeSupplierProductConfigurations.productConfigurations.items = [
          action.payload,
          ...activeSupplierProductConfigurations.productConfigurations.items,
        ];
      },
    )
    .addCase(
      updateProductConfigurationsList,
      ({ activeSupplierProductConfigurations }, action) => {
        if (activeSupplierProductConfigurations.productConfigurations) {
          const configurationToUpdateIndex = activeSupplierProductConfigurations.productConfigurations.items.findIndex(
            (productConfiguration) =>
              productConfiguration.id === action.payload.id,
          );
          if (configurationToUpdateIndex !== -1) {
            activeSupplierProductConfigurations.productConfigurations.items[
              configurationToUpdateIndex
            ] = action.payload;
          }
        }
      },
    )
    .addCase(
      deleteProductConfigurationsFromList,
      ({ activeSupplierProductConfigurations }, action) => {
        if (activeSupplierProductConfigurations.productConfigurations.items) {
          activeSupplierProductConfigurations.productConfigurations.items = activeSupplierProductConfigurations.productConfigurations.items.filter(
            (productConfiguration) =>
              productConfiguration.id !== action.payload,
          );
        }
      },
    )
    .addCase(fetchSupplierById.pending, (state) => {
      state.activeSupplier.isLoading = true;
      state.errors.activeSupplier = null;
    })
    .addCase(fetchSupplierById.fulfilled, (state, action) => {
      state.activeSupplier.supplier = action.payload;
      state.activeSupplier.isLoading = false;
    })
    .addCase(fetchSupplierById.rejected, (state, action) => {
      state.activeSupplier.isLoading = false;
      state.errors.activeSupplier = action.payload || 500;
    })
    .addCase(fetchSuppliers.pending, (state) => {
      state.isLoading = true;
      state.errors.suppliersList = null;
    })
    .addCase(fetchSuppliers.fulfilled, (state, action) => {
      state.suppliers = action.payload;
      state.isLoading = false;
    })
    .addCase(fetchSuppliers.rejected, (state, action) => {
      state.isLoading = false;
      state.errors.suppliersList = action.payload || 500;
    })
    .addCase(fetchSupplierAccounts.pending, (state) => {
      state.activeSupplierAccounts.isLoading = true;
      state.errors.supplierAccounts = null;
    })
    .addCase(fetchSupplierAccounts.fulfilled, (state, action) => {
      state.activeSupplierAccounts.supplierAccounts = action.payload;
      state.activeSupplierAccounts.isLoading = false;
    })
    .addCase(fetchSupplierAccounts.rejected, (state, action) => {
      state.activeSupplierAccounts.isLoading = false;
      state.errors.supplierAccounts = action.payload || 500;
    })
    .addCase(fetchSupplierProductConfigurations.pending, (state) => {
      state.activeSupplierProductConfigurations.isLoading = true;
      state.errors.supplierProductConfigurations = null;
    })
    .addCase(fetchSupplierProductConfigurations.fulfilled, (state, action) => {
      state.activeSupplierProductConfigurations.productConfigurations =
        action.payload;
      state.activeSupplierProductConfigurations.isLoading = false;
    })
    .addCase(fetchSupplierProductConfigurations.rejected, (state, action) => {
      state.activeSupplierProductConfigurations.isLoading = false;
      state.errors.supplierProductConfigurations = action.payload || 500;
    })
    .addCase(fetchSupplierLiveCatalogs.pending, (state, action) => {
      state.supplierCatalogs.isLoading = true;
      state.errors.supplierCatalogs = null;
    })
    .addCase(fetchSupplierLiveCatalogs.fulfilled, (state, action) => {
      state.supplierCatalogs.supplierCatalogs = action.payload;
      state.supplierCatalogs.isLoading = false;
    })
    .addCase(fetchSupplierLiveCatalogs.rejected, (state, action) => {
      state.supplierCatalogs.isLoading = false;
      state.errors.supplierCatalogs = action.payload || 500;
    })
    .addCase(resetSupplierLiveCatalogError, (state) => {
      state.errors.supplierCatalogs = null;
    });
});
