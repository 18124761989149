import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, CardsHistoryModel } from './Models';

export const getCardsHistory = async (
  params: Arguments,
): Promise<CardsHistoryModel> => {
  const response = await apiClient.get(ENDPOINTS.CARDS_HISTORY, { params });

  return response.data;
};
