import { InventoryState } from 'store/inventory/Models';
import { createReducer } from '@reduxjs/toolkit';
import {
  fetchReservedProductInventory,
  saveFilterStatusReservedProductInventory,
} from './actions';

export const initialState: InventoryState = {
  inventory: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: false,
  error: null,
  isFilterActive: false,
};

export const reservedProductInventory = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(fetchReservedProductInventory.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchReservedProductInventory.fulfilled, (state, action) => {
        state.inventory = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchReservedProductInventory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 500;
      })
      .addCase(saveFilterStatusReservedProductInventory, (state, action) => {
        state.isFilterActive = action.payload;
      });
  },
);
