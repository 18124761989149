import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { RootState } from '../rootReducer';

export const getShoppingCartsState = (state: RootState) =>
  state.shoppingCarts || initialState;

export const shoppingCartsFilterState = createSelector(
  getShoppingCartsState,
  (state) => state.filter,
);

export const shoppingCartsList = createSelector(
  getShoppingCartsState,
  (state) => state.shoppingCartsList,
);
export const shoppingCartsListCount = createSelector(
  getShoppingCartsState,
  (state) => state.shoppingCartsList.count,
);

export const shoppingCartsListLoading = createSelector(
  getShoppingCartsState,
  (state) => state.isLoading,
);

export const shoppingCartsListError = createSelector(
  getShoppingCartsState,
  (state) => state.errors.shoppingCartsList,
);

export const shoppingCartsListFilterStatus = createSelector(
  getShoppingCartsState,
  (state) => state.isFilterActive,
);

export const activeShoppingCart = createSelector(
  getShoppingCartsState,
  (state) => state.activeShoppingCart.shoppingCart,
);

export const activeShoppingCartLoading = createSelector(
  getShoppingCartsState,
  (state) => state.activeShoppingCart.isLoading,
);

export const activeShoppingCartError = createSelector(
  getShoppingCartsState,
  (state) => state.errors.activeShoppingCart,
);

export const activeShoppingCartClientName = createSelector(
  getShoppingCartsState,
  (state) => state.activeShoppingCart.shoppingCart?.clientName,
);

export const isActiveShoppingCartDeleted = createSelector(
  getShoppingCartsState,
  (state) => {
    if (!state.activeShoppingCart.shoppingCart) {
      return false;
    }
    return state.activeShoppingCart.shoppingCart.suppliers.length
      ? false
      : true;
  },
);
