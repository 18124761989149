import { apiClient } from 'config/auth';
import * as types from './Models';

export const fetchCatalogProducts = async (
  catalogId: string,
  params: types.Arguments,
): Promise<types.Response> => {
  const response = await apiClient.get(`/Catalogs/${catalogId}/products`, {
    params,
  });

  return response.data;
};
