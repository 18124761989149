import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { EnebaAccountList } from './Models';

export const getEnebaAccountList = async (): Promise<EnebaAccountList[]> => {
  const response = await apiClient.get(
    `${ENDPOINTS.ENEBA}${ENDPOINTS.ENEBA_ACCOUNT_LIST}`,
  );

  return response.data;
};
