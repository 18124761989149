import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  BinanceRequestModel,
  Arguments,
} from 'api/binance/getBinanceRequest/Models';
import { getBinanceRequest } from 'api/binance/getBinanceRequest';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchBinanceRequests = createAsyncThunk<
  BinanceRequestModel,
  Arguments,
  AsyncThunkOptions
>('binance/fetchBinanceRequests', async (queryParams, { rejectWithValue }) => {
  try {
    return await getBinanceRequest(queryParams);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});
