export enum SupplierMappingFilterField {
  Search = 'searchText',
  Country = 'country',
  Currency = 'currency',
  Brand = 'brandId',
  Supplier = 'supplierId',
  IsIntegrated = 'isIntegrated',
  IncludeV2 = 'includeV2',
}

export interface SupplierMappingFilterInputs {
  [SupplierMappingFilterField.Search]: string;
  [SupplierMappingFilterField.Country]: string;
  [SupplierMappingFilterField.Currency]: string;
  [SupplierMappingFilterField.Brand]: string;
  [SupplierMappingFilterField.Supplier]: string;
  [SupplierMappingFilterField.IsIntegrated]?: boolean;
  [SupplierMappingFilterField.IncludeV2]?: boolean;
}
