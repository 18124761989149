import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CategoryModel,
  Arguments,
} from 'api/categories/getCategoryList/Models';
import { getCategoryList } from 'api/categories/getCategoryList';
import { FilterInputs } from 'entries/categories/filter';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const setCategoriesSearch = createAction<FilterInputs>(
  'category/searchCategories',
);

export const fetchCategories = createAsyncThunk<
  CategoryModel,
  Arguments,
  AsyncThunkOptions
>('category/fetchCategories', async (queryParams, { rejectWithValue }) => {
  try {
    return await getCategoryList(queryParams);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});
