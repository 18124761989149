import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCatalogProducts } from 'api/catalogs/fetchCatalogProducts';
import { Response, Arguments } from 'api/catalogs/fetchCatalogProducts/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchEditableProducts = createAsyncThunk<
  Response,
  { catalogId: string } & { args: Arguments },
  AsyncThunkOptions
>(
  'editableCatalogProducts/fetchEditableProducts',
  async (params, { rejectWithValue }) => {
    try {
      return await fetchCatalogProducts(params.catalogId, params.args);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
