import { createReducer } from '@reduxjs/toolkit';
import { fetchEditableIntegratedProducts } from 'store/products/editableIntegratedProducts/actions';

import { EditableIntegratedProductsState } from './Models';

export const initialState: EditableIntegratedProductsState = {
  count: 0,
  items: [],
  pageIndex: 0,
  pageSize: 0,
  isLoading: true,
  error: null,
};

export const editableIntegratedProducts = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(fetchEditableIntegratedProducts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchEditableIntegratedProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.count = action.payload.count;
        state.pageSize = action.payload.pageSize;
        state.pageIndex = action.payload.pageIndex;
        state.items = action.payload.items.map((product) => ({
          ...product,
          isValid: true,
          tableData: {
            checked: false,
          },
        }));
      })
      .addCase(fetchEditableIntegratedProducts.rejected, (state, action) => {
        state.error = action.payload || 500;
        state.isLoading = false;
      });
  },
);
