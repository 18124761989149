import { createReducer } from '@reduxjs/toolkit';

import { G2aCategoryState } from './Models';
import { fetchG2aCategoryTable } from './actions';

export const initialState: G2aCategoryState = {
  g2aCategory: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: true,
  isFilterActive: false,
  error: null,
};

export const g2aSelectCategoryTable = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchG2aCategoryTable.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchG2aCategoryTable.fulfilled, (state, action) => {
      state.isLoading = false;
      state.g2aCategory = action.payload;
    })
    .addCase(fetchG2aCategoryTable.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    });
});
