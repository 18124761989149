import { InventoryState } from 'store/inventory/Models';
import { createReducer } from '@reduxjs/toolkit';
import { fetchEditableProductInventory } from 'store/inventory/editableProductInventory/actions';

export const initialState: InventoryState = {
  inventory: {
    pageSize: 0,
    pageIndex: 0,
    count: 0,
    items: [],
  },
  isLoading: false,
  error: null,
};

export const editableProductInventory = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(fetchEditableProductInventory.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchEditableProductInventory.fulfilled, (state, action) => {
        state.inventory = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchEditableProductInventory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 500;
      });
  },
);
