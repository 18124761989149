import { getShoppingCartById, getShoppingCartsList } from 'api/shoppingCart';
import { Arguments, Response } from 'api/shoppingCart/getShoppingCarts/Models';
import {
  Product,
  ShoppingCart,
} from 'api/shoppingCart/getShoppingCartById/Models';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { FilterInputs } from 'entries/shoppingCart/shoppingCartsListFilters';

type AsyncThunkOptions = {
  rejectValue: number;
};
export const setShoppingCartSearch = createAction<FilterInputs>(
  'shoppingCart/findCart',
);

export const fetchShoppingCartsList = createAsyncThunk<
  Response,
  Arguments,
  AsyncThunkOptions
>(
  'shoppingCart/fetchShoppingCartsList',
  async (queryParams, { rejectWithValue }) => {
    try {
      const data = await getShoppingCartsList(queryParams);
      return data;
    } catch (error) {
      const err =
        error.message === 'Network Error'
          ? 503
          : error.status
          ? error.status
          : error.response.status;
      if (error.response && error.response.status >= 500) {
        console.error({
          message: error.response.data.message,
          status: error.response.status,
          statusText: error.response.statusText,
        });
      }
      return rejectWithValue(err);
    }
  },
);

export const fetchShoppingCartById = createAsyncThunk<
  ShoppingCart,
  number | string,
  AsyncThunkOptions
>(
  'shoppingCart/fetchShoppingCartById',
  async (shoppingCartId, { rejectWithValue }) => {
    try {
      return await getShoppingCartById(shoppingCartId);
    } catch (error) {
      if (error.response && error.response.status >= 500) {
        console.error({
          message: error.response.data.message,
          status: error.response.status,
          statusText: error.response.statusText,
        });
      }
      return rejectWithValue(error.response.status);
    }
  },
);

export const updateProduct = createAction<Product>(
  'shoppingCart/updateProduct',
);

export const changeCommentText = createAction<string>(
  'shoppingCart/changeCommentText',
);

export const deleteProduct = createAction<Product>(
  'shoppingCart/deleteProduct',
);

export const resetActiveShoppingCart = createAction(
  'shoppingCart/resetActiveShoppingCart',
);

export const resetActiveShoppingCartError = createAction(
  'shoppingCart/resetActiveShoppingCartError',
);

export const saveShoppingCartsFilterStatus = createAction<boolean>(
  'shoppingCart/saveShoppingCartsFilterStatus',
);
