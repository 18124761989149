import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProductKinguinMainTable } from 'api/kinguin/getProductKinguinMainTable';
import {
  KinguinProductsMainTableModel,
  Arguments,
} from 'api/kinguin/getProductKinguinMainTable/Models';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchKinguinEditableProduct = createAsyncThunk<
  KinguinProductsMainTableModel,
  Arguments,
  AsyncThunkOptions
>(
  'eneba/fetchEditableProductKinguin',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getProductKinguinMainTable(queryParams);
    } catch (error) {
      if (error.response && error.response.status >= 500) {
        console.error({
          message: error.response.data.message,
          status: error.response.status,
          statusText: error.response.statusText,
        });
      }
      return rejectWithValue(error.response.status);
    }
  },
);
