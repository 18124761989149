import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, AnalyticProductsModel } from './Models';

export const getAnalyticsProducts = async (
  params: Arguments,
): Promise<AnalyticProductsModel> => {
  const response = await apiClient.get(ENDPOINTS.ANALYTICS_PRODUCTS, {
    params,
  });

  return response.data;
};
