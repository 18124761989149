import { createReducer } from '@reduxjs/toolkit';
import { fetchAnalyticsProducts } from './actions';
import { AnalyticProductState } from './Models';

export const initialState: AnalyticProductState = {
  analyticsProduct: {
    pageIndex: 0,
    pageSize: 0,
    count: 0,
    items: [],
  },
  isLoading: false,
  error: null,
};

export const analyticsProductTable = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAnalyticsProducts.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchAnalyticsProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // // @ts-ignore
      // const { data } = action.payload;
      state.analyticsProduct = action.payload;
    })
    .addCase(fetchAnalyticsProducts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    });
});
