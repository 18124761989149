import colors from './colors';

export const styledComponentTheme = {
  colors: {
    primary: colors.primary,
    primaryLight: colors.primaryLight,
    primaryDark: colors.primaryDark,
    warningLight: colors.warningLight,
    warningMain: colors.warningMain,
    warningDark: colors.warningDark,
    successLight: colors.successLight,
    successMain: colors.successMain,
    successDark: colors.successDark,
    errorLight: colors.errorLight,
    errorMain: colors.errorMain,
    errorDark: colors.errorDark,
    background: colors.background,
    cardBg: colors.cardBg,
    headerBg: colors.headerBg,
    textColor: colors.textColor,
    grey300: colors.grey300,
    grey50: colors.grey50,
    greyText: colors.greyColor,
    activeLink: colors.activeLink,
    disabledInputColor: colors.disabledInputColor,
    activeLinkSidebar: colors.activeLinkSidebar,
    darkBlueBg: colors.darkBlueBg,
    lightBlueBg: colors.lightBlueBg,
    sidebarText: colors.sidebarText,
    sidebarActiveIcon: colors.sidebarActiveIcon,
    sidebarSubText: colors.sidebarSubText,
    imgPlaceholder: colors.imgPlaceholder,
    solidWhite: colors.solidWhite,
    lightViolet: colors.lightViolet,
    redBasic: colors.redBasic,
    dark: colors.dark,
    brightGreen: colors.brightGreen,
    gray: colors.gray,
    tableHeaderCell: colors.tableHeaderCell,
    lightGrey: colors.lightGrey,
    black: colors.black,
    preloaderOpacity: colors.preloaderOpacity,
    denominationBg: colors.denominationBg,
    scrollBar: colors.scrollBar,
    blueBorder: colors.blueBorder,
    dropzoneActiveBg: colors.dropzoneActiveBg,
    dropzoneSubtitle: colors.dropzoneSubtitle,
    actionsIcon: colors.actionsIcon,
    uploadedFilesTitle: colors.uploadedFilesTitle,
    attachFileIcon: colors.attachFileIcon,
    emptyCartTitle: colors.emptyCartTitle,
    lightBlack: colors.lightBlack,
    extraLightBlack: colors.extraLightBlack,
    paleBlue: colors.paleBlue,
  },
  sidebarWidth: '240px',
  sidebarWidthSmall: '70px',
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 800,
  },
  fontFamily: `'Poppins'`,
};
