import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { simpleToast } from 'modules/ui/atoms/ToastContainer';

export const toastMessage = (
  message: string,
  type: 'success' | 'warning' | 'error',
  position?:
    | 'top-right'
    | 'top-center'
    | 'top-left'
    | 'bottom-right'
    | 'bottom-center'
    | 'bottom-left'
    | undefined,
) => {
  toast.clearWaitingQueue();

  return toast(simpleToast(message, type), {
    type,
    position,
  });
};
