import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, Response } from './Models';

export const getProductsApi = async (params: Arguments): Promise<Response> => {
  const response = await apiClient.get(ENDPOINTS.PRODUCT_LIST, {
    params,
  });

  return response.data;
};
