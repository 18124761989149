export const ADMIN = 'Admin';
export const USER = 'User';
export const FINANCE_MANAGER = 'FinanceManager';
export const CUSTOMER_SUPPORT = 'CustomerSupport';
export const SALES = 'Sales';
export const ACCOUNT_MANAGER = 'AccountManager';
export const OPERATIONS_EXECUTIVE = 'OperationsExecutive';
export const MARKETPLACE_MANAGER = 'MarketplaceManager';
export const TECH = 'Tech';

export const ROLES = {
  ADMIN,
  USER,
  FINANCE_MANAGER,
  CUSTOMER_SUPPORT,
  SALES,
  ACCOUNT_MANAGER,
  OPERATIONS_EXECUTIVE,
  MARKETPLACE_MANAGER,
  TECH,
} as const;
